import { useMemo } from 'react';
import { Location, Params, useLocation, useMatch, useNavigate, useNavigationType, useParams } from 'react-router';

type NavigationAction = 'POP' | 'PUSH' | 'REPLACE';

type UseRouterReturn = {
  pathname: string;
  query: Record<string, any>;
  match: {
    path: string;
    url: string;
    isExact: boolean;
    params: Readonly<Params<string>>;
  };
  location: Location;
  history: {
    length: number;
    action: NavigationAction;
    location: Location;
    push: (path?: any, state?: unknown) => void;
    replace: (path?: any, state?: unknown) => void;
    goBack: () => void;
  };
};

export default function useRouter(): UseRouterReturn {
  const navigate = useNavigate();

  const location = useLocation();

  const rawParams = useParams();

  const match = useMatch(location.pathname);

  const navigationType = useNavigationType() as NavigationAction;

  const params = useMemo((): Readonly<Params<string>> => {
    return rawParams ?? {};
  }, [rawParams]);

  const safeMatch = useMemo(() => {
    if (!match) {
      return {
        path: '',
        url: '',
        isExact: false,
        params,
      };
    }
    const { pathname, pattern } = match;

    return {
      path: pattern.path,
      url: pathname,
      isExact: pattern.end ?? false,
      params,
    };
  }, [match, params]);

  const history = useMemo(
    () => ({
      length: window.history.length,
      action: navigationType,
      location,
      push: (path?: any, state?: unknown) => navigate(path, { state }),
      replace: (path?: any, state?: unknown) => navigate(path, { replace: true, state }),
      goBack: () => navigate(-1),
    }),
    [navigate, location, navigationType]
  );

  return useMemo(
    () => ({
      pathname: location.pathname,
      query: params,
      match: safeMatch,
      location,
      history,
    }),
    [location, params, safeMatch, history]
  );
}
