import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router';
import Icon from '../Icon';

interface IProps {
  children: any;
  buttonBack?: {
    pathname: string;
    content: any;
    link: boolean;
  };
  mode?: string;
}

const HeaderControl: FC<IProps> = function ({ buttonBack, children, mode = 'admin' }): ReactElement {
  const renderButtonBack = () => {
    return (
      buttonBack?.pathname && (
        <Link data-testid="header-back-btn" className="header-control__button" to={buttonBack.pathname}>
          {buttonBack.link && <Icon name="ELArrowLineLeft" className="header-control__button-arrow" />}
          {buttonBack.content}
        </Link>
      )
    );
  };

  const classesHeaderControl = classNames('header-control', {
    [`header-control--${mode}`]: mode,
  });

  return (
    <div className={classesHeaderControl}>
      {renderButtonBack()}
      <div className="header-control__panel">{children}</div>
    </div>
  );
};

export default HeaderControl;
