import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { setDisplayView } from '../../app/app.actions';
import { DisplayView } from '../../app/app.pipes';
import { typesView } from '../../app/app.types';
import { useEvent } from '../../hooks';
import { IReduxStore } from '../../store/serviceReducer';
import Icon from '../Icon';

interface IProps {
  children?: ReactNode;
  className?: string;
  hide?: string;
  initialWidth?: number;
  minWidth?: number;
  dark?: boolean;
  type?: string;
  session?: any;
  onChange?(data: boolean): void;
  onClick?(e: any): void;
  offset?: string;
}

interface IFields {
  [key: string]: string;
}
interface IParts {
  children?: ReactNode;
  className?: string;
  params?: any;
}
const field: IFields = {
  app: 'app',
  head: 'app__head',
  foot: 'app__foot',
  body: 'app__body',
  aside: 'app__aside',
  asideHead: 'app__aside-head',
  asideBody: 'app__aside-body',
  asideInner: 'app__aside-inner',
  scroller: 'app__body-scroller',
  main: 'app__body-main',
  widget: 'app__body-widget',
  content: 'app__body-content',
  context: 'app__body-context',
  route: 'app__route',
  container: 'app__container',
  containerInner: 'app__container-inner',
  settings: 'app__settings',
  settingsRow: 'app__settings__row',
  mobileModal: 'app__mobile-modal',
};

const LayoutPart: FC<IParts> = ({ children, className, params }) => (
  <div className={className} {...params}>
    {children}
  </div>
);

export const Head: FC<IProps> = ({ children }) => <LayoutPart className={field.head}>{children}</LayoutPart>;

export const Body: FC<IProps> = ({ children }) => <LayoutPart className={field.body}>{children}</LayoutPart>;

export const Foot: FC<IProps> = ({ children }) => <LayoutPart className={field.foot}>{children}</LayoutPart>;

export const Aside: FC<any> = ({ children, initialWidth = 380, view, setDisplayViewAction }) => {
  const { COMPACT, DEFAULT, MOBILE } = typesView;

  const { onCalculateDesktop, onCalculateCompact, onCalculateMobile } = DisplayView();

  let stopView: NodeJS.Timeout | null = null;

  const onChangeDisplayView = () => {
    if (onCalculateCompact(window.innerWidth)) {
      return setDisplayViewAction(COMPACT);
    }

    if (onCalculateMobile(window.innerWidth)) {
      return setDisplayViewAction(MOBILE);
    }

    setDisplayViewAction(DEFAULT);
  };

  const onChangeStopControlView = () => {
    if (stopView) clearTimeout(stopView);
    stopView = setTimeout(onChangeDisplayView, 0);
  };

  useEffect(() => {
    onChangeDisplayView();
  }, []);

  useEvent('resize', onChangeStopControlView);

  const onToggleCompact = () => {
    setDisplayViewAction(view === COMPACT ? DEFAULT : COMPACT);
  };

  const renderScroller = () => {
    return (
      view !== MOBILE &&
      !onCalculateCompact() && (
        <div className={field.scroller}>
          <span onClick={onToggleCompact}>
            <Icon name="ArrowSwipe" />
          </span>
        </div>
      )
    );
  };

  const params = {
    className: classNames(field.aside, {
      [`${field.aside}--compact`]: view === COMPACT,
      [`${field.aside}--active`]: view === COMPACT && onCalculateDesktop(window.innerWidth),
      [`${field.aside}--mobile`]: view === MOBILE,
    }),
    ...(view !== MOBILE && {
      style: {
        minWidth: initialWidth,
        width: initialWidth,
      },
    }),
  };

  return (
    <div {...params}>
      {children}
      {renderScroller()}
    </div>
  );
};

export const Main: FC<IProps> = ({ children }) => <LayoutPart className={field.main}>{children}</LayoutPart>;

export const Widget: FC<any> = ({ children, view }) => {
  const isMobile = view === typesView.MOBILE;

  return !isMobile ? <div className={field.widget}>{children}</div> : null;
};

export const Content: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.content} ${className}`}>{children}</LayoutPart>
);

export const Container: FC<IProps> = ({ children, className = '', offset = '' }) => (
  <LayoutPart
    className={classNames(field.container, className, {
      [`${field.container}--${offset}`]: offset,
    })}
  >
    {children}
  </LayoutPart>
);

export const Settings: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.settings} ${className}`}>{children}</LayoutPart>
);

export const SettingsRow: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.settingsRow} ${className}`}>{children}</LayoutPart>
);

export const ContainerInner: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.containerInner} ${className}`}>{children}</LayoutPart>
);

export const Route: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.route} ${className}`}>{children}</LayoutPart>
);

export const AsideHead: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.asideHead} ${className}`}>{children}</LayoutPart>
);

export const AsideBody: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.asideBody} ${className}`}>{children}</LayoutPart>
);

export const AsideInner: FC<IProps> = ({ children, className = '' }) => (
  <LayoutPart className={`${field.asideInner} ${className}`}>{children}</LayoutPart>
);

export const MobileModal: FC<IProps> = ({ children, className = '', onClick }) => (
  <LayoutPart className={`${field.mobileModal} ${className}`} params={{ onClick }}>
    {children}
  </LayoutPart>
);

const mapStateToProps__Aside = (store: IReduxStore) => ({
  view: store.app.view,
});

const mapStateToProps__Widget = (store: IReduxStore) => ({
  view: store.app.view,
});

const mapDispatchToProps__Aside = (dispatch: Dispatch) => ({
  setDisplayViewAction: bindActionCreators(setDisplayView, dispatch),
});

const Layout = (props: IProps & { children?: ReactNode }) => {
  const { children, dark, type, className } = props;

  const classes = classNames(field.app, className, {
    dark: dark,
    [`app--${type}`]: type,
  });

  return <div className={classes}>{children}</div>;
};

Layout.Head = Head;
Layout.Body = Body;
Layout.Foot = Foot;
Layout.Aside = connect(mapStateToProps__Aside, mapDispatchToProps__Aside)(Aside);
Layout.Main = Main;
Layout.Widget = connect(mapStateToProps__Widget)(Widget);
Layout.Content = Content;
Layout.Route = Route;
Layout.Container = Container;
Layout.ContainerInner = ContainerInner;
Layout.Settings = Settings;
Layout.SettingsRow = SettingsRow;
Layout.AsideHead = AsideHead;
Layout.AsideBody = AsideBody;
Layout.AsideInner = AsideInner;
Layout.MobileModal = MobileModal;

export default Layout;
