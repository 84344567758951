import './index.scss';
import { isAfter } from 'date-fns/isAfter';
import { parseISO } from 'date-fns/parseISO';
import React, { FC, ReactElement, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Modal from '../../../components/ModalDeprecated';
import { useMobile, useRouter, useToState } from '../../../hooks';
import translate from '../../../i18n/translate';
import { getRoute } from '../../../routes/client';
import { URI } from '../../../routes/uri';
import { IReduxStore } from '../../../store/serviceReducer';
import { parserName } from '../../../utils';
import { setAlert } from '../../app.actions';
import { Button, DivTarget, Icon, LocationDate, PopoverTarget, Text } from '../../app.modules';
import { eventAddDP } from '../../Events/events.actions';
import { typeSignatureEventsList } from '../../Events/events.types';
import { setAgencyInvitation, setEventInvitation, setEventInvitationMembers } from '../../Mail/mail.services';
import Cancel from '../../Modal/storage/Cancel';
import { notificationAdd, notificationDelete, setNotificationData } from '../notifications.actions';
import { deleteNotification, getNotifications } from '../notifications.services';
import { typBell } from '../notifications.types';

interface IProps {
  getNotificationsAction: any;
  deleteNotificationAction: any;
  eventUserInviteSetAction: any;
  agencySubmitInviteAction: any;
  setNotificationDataAction: any;
  setAlertAction: any;
  notificationData: any;
  socketStream: any;
  notificationDeleteAction: any;
  notificationAddAction: any;
  eventMembersInvitationsAcceptAction: any;
  eventAddDPAction: any;
}

const NotificationBell: FC<IProps> = function ({
  getNotificationsAction,
  deleteNotificationAction,
  eventUserInviteSetAction,
  agencySubmitInviteAction,
  setNotificationDataAction,
  notificationData,
  socketStream,
  notificationDeleteAction,
  notificationAddAction,
  eventAddDPAction,
  eventMembersInvitationsAcceptAction,
}): ReactElement {
  const [state, setState] = useToState({
    isShowCancelInviteModal: false,
    activeItem: {},
  });

  const { history, pathname } = useRouter();

  const [isMobile] = useMobile();

  const onLoadDefault = () => {
    socketStream.openConnect('notifications', (response) => {
      notificationAddAction(response);
    });

    getNotificationsAction().then((response) => {
      setNotificationDataAction(response);
    });

    return () => {
      socketStream.closeConnect('notifications');
    };
  };

  useEffect(onLoadDefault, []);

  const onAddEvents = (data) => {
    const startDate = data?.startDate ? isAfter(parseISO(data.startDate), Date.now()) : false;

    const endDate = data?.endDate ? isAfter(Date.now(), parseISO(data.endDate)) : false;

    const typesEvents = {
      [typeSignatureEventsList.COMPLETED]: !startDate && endDate,
      [typeSignatureEventsList.CURRENT]: !startDate && !endDate,
      [typeSignatureEventsList.UPCOMING]: startDate && !endDate,
    };

    const [typeStatus] = Object.keys(typesEvents).filter((key) => typesEvents[key]);

    eventAddDPAction(data, typeStatus);
  };

  const onAccept = (data) => () => {
    const token = data.data.token;

    const method = 'accept';

    token &&
      eventUserInviteSetAction(token, { method }).then(() => {
        notificationDeleteAction(data.id);
        data.meta?.event && onAddEvents(data.meta.event);
      });
  };

  const onRejectEventMember = (data) => () => {
    const token = data.data.token;

    const method = 'reject';

    token &&
      eventMembersInvitationsAcceptAction(token, { method }).then(() => {
        setState({
          isShowCancelInviteModal: false,
        });

        notificationDeleteAction(data.id);
      });
  };

  const onAcceptAgency = (data) => () => {
    const token = data.data.token;

    const method = 'accept';

    token &&
      agencySubmitInviteAction(token, { method }).then(() => {
        notificationDeleteAction(data.id);
      });
  };

  const onAcceptEventMember = (data) => () => {
    const token = data.data.token;

    const method = 'accept';

    token &&
      eventMembersInvitationsAcceptAction(token, { method }).then(() => {
        setState({
          isShowCancelInviteModal: false,
        });

        notificationDeleteAction(data.id);
      });
  };

  const onReject = (data) => () => {
    const token = data.data.token;

    const method = 'reject';

    token &&
      eventUserInviteSetAction(token, { method }).then(() => {
        setState({
          isShowCancelInviteModal: false,
        });

        notificationDeleteAction(data.id);
      });
  };

  const onRejectAgency = (data) => () => {
    const token = data.data.token;

    const method = 'reject';

    token &&
      agencySubmitInviteAction(token, { method }).then(() => {
        notificationDeleteAction(data.id);
      });
  };

  const onOpenHomework = (data) => () => {
    deleteNotificationAction(data).then(() => {
      notificationDeleteAction(data.id);
    });

    history.push(
      getRoute('AP/E/-/P/U/H', 'path', { full: true })(
        data?.data.eventId,
        data?.data?.participantId,
        data?.data.elementId
      )
    );
  };

  const renderThumbEvent = (data) => {
    const image = data?.meta?.event?.miniCoverFileId;

    return image ? (
      <img className="notification-small__thumb-image" src={URI.INVITE_EVENTS.MINI_COVER(data.meta.token)} alt="" />
    ) : (
      <Icon name="CoverEventSmall" />
    );
  };

  const renderEventsParticipants = (item) => {
    return (
      <>
        <div className="notification-small__data">
          <div className="notification-small__thumb">{renderThumbEvent(item)}</div>
          <Text variant="md" className="notification-small__title">
            <strong>Приглашение в мероприятие</strong>
            <br />
            <strong>{item.meta.event.name}</strong>
          </Text>
        </div>
        <Text variant="md" className="notification-small__desc">
          {item.meta.event.description}
        </Text>
        <LocationDate date={item.createdAt}>
          {({ getStartDate }) => (
            <Text variant="sm" className="notification-small__date">
              {getStartDate('dd.MM.yy в HH:mm')}
            </Text>
          )}
        </LocationDate>
        <div className="notification-small__panel">
          <Button
            className="notification-small__reject"
            size="sm"
            variant="outlined"
            onClick={() => onToggleModal(item)}
          >
            {translate('g.btn.canceled')}
          </Button>
          <Button size="sm" onClick={onAccept(item)}>
            {translate('g.btn.accept')}
          </Button>
        </div>
      </>
    );
  };

  const renderEventsNewParticipants = (item) => {
    const firstName = item.data.userInfo.firstName;

    const lastName = item.data.userInfo.lastName;

    const patronymic = item.data.userInfo.patronymic;

    return (
      <>
        <div className="notification-small__data">
          <Text variant="md" className="notification-small__title">
            На мероприятие дабавлен новый участник:
            <br />
            <strong>{item.data.eventInfo.name}</strong>
          </Text>
        </div>
        <Text variant="md" className="notification-small__desc">
          {parserName(firstName, lastName, patronymic)}
        </Text>
        <LocationDate date={item.createdAt}>
          {({ getStartDate }) => (
            <Text variant="sm" className="notification-small__date">
              {getStartDate('dd.MM.yy в HH:mm')}
            </Text>
          )}
        </LocationDate>
      </>
    );
  };

  const renderEventsMembers = (item) => {
    return (
      <>
        <div className="notification-small__data">
          <div className="notification-small__thumb">{renderThumbEvent(item)}</div>
          <Text variant="md" className="notification-small__title">
            <strong>Приглашение в мероприятие</strong>
          </Text>
        </div>
        <Text variant="md" className="notification-small__desc">
          {item.meta.event.name}
        </Text>
        <LocationDate date={item.createdAt}>
          {({ getStartDate }) => (
            <Text variant="sm" className="notification-small__date">
              {getStartDate('dd.MM.yy в HH:mm')}
            </Text>
          )}
        </LocationDate>
        <div className="notification-small__panel">
          <Button
            className="notification-small__reject"
            size="sm"
            variant="outlined"
            onClick={() => onToggleModal(item)}
          >
            {translate('g.btn.canceled')}
          </Button>
          <Button size="sm" onClick={onAcceptEventMember(item)}>
            {translate('g.btn.accept')}
          </Button>
        </div>
      </>
    );
  };

  const renderHomeworkReview = (item) => {
    return (
      <>
        <div className="notification-small__data">
          <Text variant="md" className="notification-small__title">
            <strong>Новое домашнее задание!</strong>
          </Text>
        </div>
        <LocationDate date={item.createdAt}>
          {({ getStartDate }) => (
            <Text variant="sm" className="notification-small__date">
              {getStartDate('dd.MM.yy в HH:mm')}
            </Text>
          )}
        </LocationDate>
        <div className="notification-small__panel">
          <Button size="sm" onClick={onOpenHomework(item)}>
            {translate('g.btn.open')}
          </Button>
        </div>
      </>
    );
  };

  const onToggleModal = (item = {}) => {
    setState((state) => ({
      ...state,
      activeItem: item,
      isShowCancelInviteModal: !state.isShowCancelInviteModal,
    }));
  };

  const onToNotification = () => {
    const rootPath = pathname.includes(getRoute('AP', 'path', { full: true }));

    history.push(getRoute(rootPath ? 'AP/N' : 'DP/N', 'path', { full: true }));
  };

  const renderAgency = (item) => {
    return (
      <>
        <div className="notification-small__data">
          <Text variant="md" className="notification-small__title">
            <strong>{item.meta.agency.name}</strong>
          </Text>
        </div>
        <Text variant="md" className="notification-small__desc">
          Приглашение на роль {item.meta.role.name}
        </Text>
        <LocationDate date={item.createdAt}>
          {({ getStartDate }) => (
            <Text variant="sm" className="notification-small__date">
              {getStartDate('dd.MM.yy в HH:mm')}
            </Text>
          )}
        </LocationDate>
        <div className="notification-small__panel">
          <Button
            className="notification-small__reject"
            size="sm"
            variant="outlined"
            onClick={() => onToggleModal(item)}
          >
            {translate('g.btn.canceled')}
          </Button>
          <Button size="sm" onClick={onAcceptAgency(item)}>
            {translate('g.btn.accept')}
          </Button>
        </div>
      </>
    );
  };

  const renderTypes = (data) => {
    const types = {
      [typBell.EVENTS]: () => renderEventsParticipants(data),
      [typBell.AGENCY]: () => renderAgency(data),
      [typBell.EVENT_INVITATIONS]: () => renderEventsMembers(data),
      [typBell.HOMEWORK_REVIEW]: () => renderHomeworkReview(data),
      [typBell.EVENT_NEW_PARTICIPANT]: () => renderEventsNewParticipants(data),
    };

    return types[data.type]();
  };

  const getAction = () => {
    const { activeItem } = state;

    const types = {
      [typBell.EVENTS]: onReject(activeItem),
      [typBell.AGENCY]: onRejectAgency(activeItem),
      [typBell.EVENT_INVITATIONS]: onRejectEventMember(activeItem),
    };

    types[activeItem.type]();
  };

  const renderNotificationsList = () => {
    const { data, total } = notificationData;

    return (
      total !== 0 && (
        <div className="notification-bell__menu">
          <Text className="notification-bell__menu-title">
            <strong>
              {translate('notice.name')} ({total})
            </strong>
          </Text>
          <div className="notification-bell__meta">
            {data.map((item) => (
              <div key={item.id} className="notification-small">
                {renderTypes(item)}
              </div>
            ))}
          </div>
        </div>
      )
    );
  };

  const renderTypesTarget = () => {
    if (!isMobile)
      return (
        <>
          <PopoverTarget target={renderNotificationsList} mode="right">
            <DivTarget className="notification-bell">
              <Icon name="ELBell" />
              {notificationData.total > 0 && (
                <Text variant="sm" className="notification-bell__count">
                  {notificationData.total}
                </Text>
              )}
            </DivTarget>
          </PopoverTarget>
          {state.isShowCancelInviteModal && (
            <Modal>
              <Cancel
                title={'Вы действительно хотите отклонить приглашение?'}
                onClose={onToggleModal}
                negativeButtonTitle={translate('g.btn.cancel')}
                positiveButtonTitle={translate('g.btn.canceled')}
                onConfirm={getAction}
              />
            </Modal>
          )}
        </>
      );

    return (
      <div className="notification-bell" onClick={onToNotification}>
        <Icon name="ELBell" />
        {notificationData.total > 0 && (
          <Text variant="sm" className="notification-bell__count">
            {notificationData.total}
          </Text>
        )}
      </div>
    );
  };

  return <>{renderTypesTarget()}</>;
};

const mapStateToProps = (store: IReduxStore) => ({
  session: store.middleware.session!,
  notificationData: store.notification.resources!,
  socketStream: store.middleware.socketStream!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  getNotificationsAction: bindActionCreators(getNotifications, dispatch),
  deleteNotificationAction: bindActionCreators(deleteNotification, dispatch),
  eventUserInviteSetAction: bindActionCreators(setEventInvitation, dispatch),
  agencySubmitInviteAction: bindActionCreators(setAgencyInvitation, dispatch),
  eventMembersInvitationsAcceptAction: bindActionCreators(setEventInvitationMembers, dispatch),
  setNotificationDataAction: bindActionCreators(setNotificationData, dispatch),
  notificationDeleteAction: bindActionCreators(notificationDelete, dispatch),
  notificationAddAction: bindActionCreators(notificationAdd, dispatch),
  eventAddDPAction: bindActionCreators(eventAddDP, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NotificationBell);
