import React from 'react';

interface IProps {
  className?: string;
}

export default function ELCheckCircle(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <rect width="22" height="22" x="1" y="1" stroke="currentColor" strokeWidth="2" rx="11" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M16.594 9.701l-5.014 5.014a.5.5 0 01-.707 0l-2.727-2.727"
      />
    </svg>
  );
}
