import './index.scss';
import { formatISO } from 'date-fns/formatISO';
import { getDay } from 'date-fns/getDay';
import { de } from 'date-fns/locale/de';
import { enUS } from 'date-fns/locale/en-US';
import { ru } from 'date-fns/locale/ru';
import React, { FC, forwardRef, ReactElement, ReactNode, useEffect, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { connect } from 'react-redux';
import { ILocationName } from '../../app/Location/location.resources';
import translate from '../../i18n/translate';
import { IReduxStore } from '../../store/serviceReducer';
import Input from '../Input';
import InputMask from '../Input/InputMask';

interface IProps {
  value?: any;
  onChange?(e): void;
  size?: string;
  placeholder?: string;
  dateFormat?: string;
  label?: ReactNode;
  startDate?: any;
  endDate?: any;
  selectsStart?: boolean;
  selectsEnd?: boolean;
  minDate?: any;
  error?: boolean;
  isWeekday?: boolean;
  showTimeInput?: boolean;
  time?: boolean;
  langName: ILocationName;
}

const TimeFrame: FC<IProps> = function ({
  value,
  onChange,
  size,
  placeholder = '"Выберите дату и время"',
  label,
  startDate,
  endDate,
  minDate,
  selectsStart,
  selectsEnd,
  error,
  isWeekday,
  showTimeInput = true,
  // dateFormat = 'Pp',
  dateFormat = 'dd.MM.yyyy HH:mm',
  langName,
  time = true,
}): ReactElement {
  const [valueDate, onChangeDate] = useState<any>(null);

  const searchKey = langName.toLowerCase();

  useEffect(() => {
    value && onChangeDate(new Date(value));
  }, [value]);

  useEffect(() => {
    const defaultNames = {
      ru,
      enUS,
      de,
    };

    registerLocale(searchKey, defaultNames[searchKey]);
  }, [langName]);

  const onChangeCalendar = (e) => {
    onChangeDate(e);

    const formatDate = e ? formatISO(e) : null;

    onChange && onChange(formatDate);
  };

  const onWeekday = (date) => {
    const day = getDay(date);

    return day !== 0 && day !== 6;
  };

  const isParams: any = {
    ...(startDate && { startDate }),
    ...(endDate && { endDate }),
    ...(minDate && { minDate }),
    ...(selectsStart && { selectsStart }),
    ...(selectsEnd && { selectsEnd }),
    ...(isWeekday && onWeekday),
  };

  const inputParams = {
    ...(error && { error }),
  };

  const mask = time ? '99.99.9999 99:99' : '99.99.9999';

  const RenderCustomInput: any = forwardRef<HTMLInputElement>(({ value, onClick, onChange }: any, ref) => (
    <InputMask
      mask={mask}
      size="md"
      value={value}
      node={{
        placeholder: translate('g.dates.time'),
        view: !value.trim(),
      }}
      innerRef={ref}
      onClick={onClick}
      onChange={onChange}
    />
  ));

  RenderCustomInput.displayName = 'DateMaskCustomInput';

  return (
    <div className="time-frame">
      <Input replace size={size} placeholder={placeholder} label={label} {...inputParams}>
        <DatePicker
          customInput={<RenderCustomInput />}
          dateFormat={dateFormat}
          locale={searchKey}
          // showTimeInput={showTimeInput}
          // showYearDropdown
          placeholderText={placeholder}
          selected={valueDate}
          onChange={onChangeCalendar}
          onBlur={onChangeCalendar}
          isClearable
          // timeInputLabel={translate('g.time')}
          showTimeSelect={showTimeInput}
          // timeSelectLabel={translate('g.time')}
          timeCaption={translate('g.time')}
          timeFormat="HH:mm"
          timeIntervals={15}
          // dateFormat="Pp"
          {...isParams}
        />
      </Input>
    </div>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  langName: store.location.langName!,
});

export default connect(mapStateToProps)(TimeFrame);
