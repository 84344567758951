import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement } from 'react';
import { Link } from 'react-router';
import {
  ContextMenu,
  DivTarget,
  Hint,
  Icon,
  LocationDate,
  Permission,
  PopoverTarget,
  Square,
  Text,
} from '../../app/app.modules';
import translate from '../../i18n/translate';
import { cardFields } from './card.types';
import CardAttach from './CardAttach';
import CardStatus from './CardStatus';

interface IProps {
  data: any;
  options?: any;
  optionsPermissions?: any;
}

const CardBlock: FC<IProps> = ({ data, options, optionsPermissions }): ReactElement => {
  const dataRow = data[0]; //TODO [Thunk]: May be rework on fields

  const cardId = dataRow?.id;

  const status = dataRow?.status;

  const onClick = dataRow?._onClick ?? dataRow?.onClick;

  const onValidationClasses = (item) => {
    return classNames(`card__block-td card__col--${item.field}`, {
      [`card__block-td--${item?.params?.status}`]: item?.params?.status,
    });
  };

  const renderThumbEvent = (value) => {
    return value ? <img className="card__image" src={value} alt="" /> : <Icon name="CoverEventSmall" />;
  };

  const renderTestInfo = (data) => {
    const questions = data?.questions;

    const attemptTime = data?.attemptTime;

    const maxScore = data?.maxScore;

    const paramsSquare = {
      ...(data?.onClick && {
        onClick: (e) => {
          e.stopPropagation();
          data.onClick(cardId);
        },
      }),
    };

    return (
      <Text variant="md" className="card__block-box">
        <Square variant="sm" className="card__block-eye" {...paramsSquare}>
          <Icon name="ELEye" />
        </Square>
        <div className="card__block-list">
          {questions && (
            <div className="card__block-box">
              {translate('g.questions')}: {questions}
            </div>
          )}
          {attemptTime > 0 && (
            <div className="card__block-box">
              {translate('g.time')}:{' '}
              <LocationDate secondTime={attemptTime * 60}>
                {({ getSecondToFormat }) => getSecondToFormat()}
              </LocationDate>
            </div>
          )}
          {maxScore > 0 && (
            <div className="card__block-box">
              {translate('g.points')}: {maxScore}
            </div>
          )}
        </div>
      </Text>
    );
  };

  const renderTestQuestions = (data) => {
    const questions = data?.countQuestions;

    const hint = data?.hint;

    const paramsSquare = {
      ...(data?.onClick && {
        onClick: (e) => {
          e.stopPropagation();
          data.onClick(dataRow?.element);
        },
      }),
    };

    const renderIcon = (ref) => (
      <Square variant="sm" innerRef={ref} className="card__block-eye" {...paramsSquare}>
        <Icon name="ELPencil" />
      </Square>
    );

    const emptyTest = (
      <Text variant="md" className="card__block-link" {...paramsSquare}>
        <Icon className="card__block-eye" name="ELPlusMini" />
        {translate('g.btn.create')}
      </Text>
    );

    const completedTest = (
      <Text variant="md" className="card__block-box">
        {hint ? (
          <Hint label={hint} move={['right', 'bottom']}>
            {(ref) => renderIcon(ref)}
          </Hint>
        ) : (
          renderIcon(null)
        )}
        <div className="card__block-box">{questions}</div>
      </Text>
    );

    return questions > 0 ? completedTest : emptyTest;
  };

  const renderParticipantsCount = (data) => {
    const isLink = data?.href;

    return (
      <Text>
        {isLink ? (
          <Link to={isLink} onClick={(e) => e.stopPropagation()} className="card__block-link card__block-area">
            {data?.text}
          </Link>
        ) : (
          data
        )}
      </Text>
    );
  };

  const parseName = (value) => {
    return value && value.replace(/<\/?[^>]+>/g, '').replaceAll('&nbsp;', ' ');
  };

  const renderCell = (data, index) => {
    const types = {
      [cardFields.MINI_COVER]: <div className="card__block-thumb">{renderThumbEvent(data?.value)}</div>,
      [cardFields.NAME]: (
        <>
          <Text className="card__block-name">
            <strong>{data?.value?.name?.substr(0, 100)}</strong>
          </Text>
          <Text variant="md" className="card__block-text">
            <div dangerouslySetInnerHTML={{ __html: parseName(data?.value?.description?.substr(0, 100)) }} />
          </Text>
        </>
      ),
      [cardFields.START_DATE]: (
        <LocationDate startDate={data.value?.startDate} endDate={data.value?.endDate}>
          {({ getStartDate, getEndDate, withInfinity }) => (
            <Text>{withInfinity(getStartDate('dd.MM.yyyy'), getEndDate('dd.MM.yyyy'), <br />)}</Text>
          )}
        </LocationDate>
      ),
      [cardFields.DATE_UPDATE]: (
        <LocationDate date={data.value}>
          {({ getStartDate }) => <Text variant="md">{getStartDate('dd.MM.yyyy в HH:mm')}</Text>}
        </LocationDate>
      ),
      [cardFields.AUTHOR]: (
        <>
          <Text>{data?.value}</Text>
        </>
      ),
      [cardFields.AGENCY]: (
        <>
          <Text>{data?.value}</Text>
        </>
      ),
      [cardFields.TEST_INFO]: renderTestInfo(data?.value),
      [cardFields.QUESTIONS]: renderTestQuestions(data?.value),
      [cardFields.PARTICIPANTS_COUNT]: renderParticipantsCount(data?.value),
      [cardFields.MAX_SCORE]: (
        <>
          <Text>{data?.value}</Text>
        </>
      ),
    };

    const searchTemplate = types[data.field];

    return searchTemplate ? (
      <div className={onValidationClasses(data)} key={index}>
        {searchTemplate}
      </div>
    ) : null;
  };

  const renderContent = () => {
    const contentCell = data?.map((item, i) => renderCell(item, i));

    return dataRow?.attach?.data.length > 0 ? <div className="card__block-content">{contentCell}</div> : contentCell;
  };

  const renderAttach = () => {
    const attach = dataRow?.attach;

    return attach && <CardAttach data={attach} />;
  };

  const classesTableRow = classNames({
    'card__block card__block--wrap': dataRow?.attach?.data.length > 0,
    card__block: !dataRow?.attach || dataRow?.attach?.data.length === 0,
  });

  const renderContentMenu = () => {
    const formationDataOptions = options.map((el) => ({
      ...el,
      cardId: dataRow?.id,
      ...(el?.hidden && {
        hidden: el.hidden(dataRow),
      }),
    }));

    return <ContextMenu className="card__block-options" data={formationDataOptions} />;
  };

  const renderOptions = () => {
    return (
      <Permission is={optionsPermissions}>
        <PopoverTarget target={renderContentMenu} mode="right">
          <DivTarget className="card__block-dots">
            <Icon name="Dots" />
          </DivTarget>
        </PopoverTarget>
      </Permission>
    );
  };

  const renderStatus = () => {
    return <CardStatus status={status} />;
  };

  const onClickFillCard = () => {
    onClick && onClick(dataRow);
  };

  return (
    <div className={classesTableRow}>
      <div data-testid={`card-${cardId}`} className="card__block-sideEffect" onClick={onClickFillCard} />
      {renderStatus()}
      {renderContent()}
      {renderAttach()}
      {options && renderOptions()}
    </div>
  );
};

export default CardBlock;
