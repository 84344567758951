import React from 'react';

interface IProps {
  className?: string;
}

export default function ELSettings(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="21" fill="none" viewBox="0 0 22 21" {...props}>
      <path
        stroke="currentColor"
        strokeWidth="2"
        d="M4.73 18.32c-.547-.455-.635-.878-.35-1.682.151-.429.162-.491.161-.92 0-.393-.017-.511-.115-.796-.341-.996-1.073-1.609-2.148-1.798-.405-.07-.582-.145-.79-.334-.365-.33-.488-.907-.488-2.286 0-.927.084-1.677.232-2.076.17-.456.54-.726 1.112-.811a2.639 2.639 0 002.153-1.824c.073-.232.092-.393.092-.775 0-.422-.015-.526-.122-.837-.156-.458-.16-.778-.015-1.095.128-.281.27-.425.779-.785.54-.383.81-.54 1.471-.86.926-.447 1.21-.512 1.662-.378.184.055.294.138.686.514.36.346.54.484.786.606a2.584 2.584 0 002.319.007c.247-.12.415-.249.788-.607.399-.382.508-.465.693-.52.466-.139.738-.072 1.77.435.966.475 1.973 1.177 2.224 1.55.236.351.264.751.088 1.244-.123.343-.154 1.069-.061 1.439.122.487.339.863.711 1.235.407.406.781.607 1.365.735.581.127.871.345 1.054.793.206.503.283 2.28.14 3.224-.096.632-.166.834-.366 1.062-.238.27-.394.352-.86.45-.91.194-1.557.677-1.932 1.441-.291.592-.334 1.405-.107 2.03.233.645.128 1.125-.34 1.543-.562.503-1.468 1.072-2.313 1.453-.552.249-.855.285-1.226.144-.18-.068-.3-.152-.459-.325-.584-.631-.902-.86-1.426-1.026-.423-.134-1.135-.132-1.546.004-.552.184-.929.458-1.338.973-.441.554-.943.675-1.728.368-.784-.308-1.875-.948-2.557-1.515zm6.795-4.51c1.235-.22 2.226-1.05 2.624-2.2a3.338 3.338 0 00-.142-2.554C13.072 7.105 10.6 6.55 8.89 7.909c-.81.644-1.285 1.737-1.217 2.802a3.335 3.335 0 003.852 3.099z"
      />
    </svg>
  );
}
