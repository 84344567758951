import React, { FC, ReactElement } from 'react';
import { getIconKey } from '../../shared/lib';
import types from './types';

interface IProps {
  name: string;
  innerRef?: any;
  onClick?(e: any): void;
  className?: string;
}

const Icon: FC<IProps> = ({ name, ...otherIconProps }): ReactElement => {
  const iconKey = getIconKey(name);

  const IconComponent = types[iconKey] ?? types[name];

  return IconComponent ? <IconComponent {...otherIconProps} /> : <></>;
};

export default Icon;
