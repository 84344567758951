import React, { FC, useEffect, useRef } from 'react';
import { useEvent, useToState } from '../hooks';
import Portal from './Portal';

interface IProps {
  onClick?(e): void;
  onClose?(e): void;
  target: any;
  mode?: any;
  children?: React.ReactNode;
}

const PopoverTarget: FC<IProps> = ({ onClick, children, target, onClose, mode }) => {
  const targetRef = useRef() as any;

  const [state, setState] = useToState({
    targetActive: false,
  });

  const onClickChange = (e) => {
    setState((state) => ({
      targetActive: !state.targetActive,
    }));

    onClick && onClick(e);
  };

  const params = {
    onClick: onClickChange,
  };

  const element = useRef() as any;

  let elements = React.Children.toArray(children) as any;

  if (elements.length > 1) {
    throw Error("Can't have more than one child");
  }

  elements = React.cloneElement(elements[0], {
    innerRef: element,
    ...params,
  });

  const top = element?.current?.getBoundingClientRect().top;

  const left = element?.current?.getBoundingClientRect().left;

  const height = element?.current?.getBoundingClientRect().height;

  const width = element?.current?.getBoundingClientRect().width;

  const renderTarget = React.createElement(target);

  const onClosePopoverOutTarget = (e) => {
    const search = e.target;

    const target = targetRef?.current;

    const targetElement = element?.current;

    if (state.targetActive && target instanceof HTMLDivElement) {
      if (!target.contains(search) && !targetElement.contains(search)) {
        setState({
          targetActive: false,
        });
        onClose && onClose(e);
      }
    }
  };

  useEffect(() => {
    if (element) {
      setState((state) => ({
        ...state,
        rectStyle: {
          position: 'fixed',
          zIndex: 10,
          top: top + height,
          left: mode === 'right' ? left + width : left,
        },
      }));
    }
  }, [target, top, left]);

  useEvent('click', onClosePopoverOutTarget);

  useEvent('wheel', onClosePopoverOutTarget);

  const renderContent = () => {
    return (
      <>
        {elements}
        {state.targetActive && (
          <Portal>
            <div data-testid="profile-control" ref={targetRef} style={state.rectStyle}>
              {renderTarget}
            </div>
          </Portal>
        )}
      </>
    );
  };

  return renderContent();
};

export default PopoverTarget;
