import './index.scss';
import classNames from 'classnames';
import React, { Component, FC, ReactNode } from 'react';
import Loader from '../Loader';
import Text from '../Text';

interface IProps {
  children?: ReactNode;
  className?: string;
  size?: string;
  off?: boolean;
  loading?: boolean;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
}

const field: IFields = {
  app: 't--settings',
  head: 't--settings__head',
  foot: 't--settings__foot',
  body: 't--settings__body',
  part: 't--settings__part',
  screen: 't--settings__screen',
  content: 't--settings__body-content',
  overflow: 't--settings__body-overflow',
  grid: 't--settings__body-grid',
  cell: 't--settings__body-cell',
  headerPart: 't--settings__header-part',
  fixed: 't--settings__fixed',
  row: 't--settings__row',
  panel: 't--settings__panel',
  label: 't--settings__label',
  button: 't--settings__button',
  options: 't--settings__options',
};

const layoutPart: FC<IParts> = ({ children, className }) => <div className={className}>{children}</div>;

const Head: FC<IProps> = ({ children }) => layoutPart({ children, className: field.head });

const Body: FC<IProps> = ({ children }) => layoutPart({ children, className: field.body });

const Foot: FC<IProps> = ({ children }) => layoutPart({ children, className: field.foot });

const Content: FC<IProps> = ({ children, loading }) =>
  loading ? <Loader /> : layoutPart({ children, className: field.content });

const Grid: FC<IProps> = ({ children }) => layoutPart({ children, className: field.grid });

const Cell: FC<IProps> = ({ children }) => layoutPart({ children, className: field.cell });

const Part: FC<IProps> = ({ children }) => <section className={field.part}>{children}</section>;

const Screen: FC<IProps> = ({ children }) => layoutPart({ children, className: field.screen });

const Overflow: FC<IProps> = ({ children }) => layoutPart({ children, className: field.overflow });

const HeaderPart: FC<IProps> = ({ children }) => layoutPart({ children, className: field.headerPart });

const Fixed: FC<IProps> = ({ children }) => layoutPart({ children, className: field.fixed });

const Panel: FC<IProps> = ({ children }) => layoutPart({ children, className: field.panel });

const Label: FC<IProps> = ({ children }) => <Text className={field.label}>{children}</Text>;

const Button: FC<IProps> = ({ children }) => layoutPart({ children, className: field.button });

const Options: FC<IProps> = ({ children, className, size = 'lg' }) => {
  const classesOptions = classNames(field.options, className, {
    [`${field.options}--${size}`]: size,
  });

  return <div className={classesOptions}>{children}</div>;
};

const Row: FC<IProps> = ({ children, className, size, off }) => {
  const classesRow = classNames(field.row, className, {
    [`${field.row}--${size}`]: size,
    [`${field.row}--off`]: off,
  });

  return <div className={classesRow}>{children}</div>;
};

class TSettings extends Component<IProps> {
  public static Head = Head;

  public static Body = Body;

  public static Foot = Foot;

  public static Content = Content;

  public static Grid = Grid;

  public static Cell = Cell;

  public static Part = Part;

  public static Screen = Screen;

  public static Overflow = Overflow;

  public static HeaderPart = HeaderPart;

  public static Fixed = Fixed;

  public static Row = Row;

  public static Panel = Panel;

  public static Label = Label;

  public static Button = Button;

  public static Options = Options;

  render(): JSX.Element {
    const { children } = this.props;

    return <div className={field.app}>{children}</div>;
  }
}

export default TSettings;
