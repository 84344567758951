import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactText, useState } from 'react';
import { NavLink } from 'react-router';
import Permission from '../Permission';

interface IProps {
  type?: string;
  data?: Array<{
    title: ReactText;
    path?: string;
    target?: boolean;
    permissions?: Array<string> | null;
    allowedRoles?: Array<string> | undefined;
    children?: Array<{
      title: ReactText;
      path: string;
    }>;
  }>;
  onChangeButton?(path: any): void;
  onChange?(e, data): void;
}

const Nav: FC<IProps> = function ({ data, onChangeButton, onChange, type }): ReactElement {
  const [state, setState] = useState({
    index: 0,
  });

  const toggleButton = (path: any) => {
    onChangeButton && onChangeButton(path);
  };

  const onCustomChange = (e, data, index) => {
    setState({
      ...state,
      index,
    });

    data?.onClick && data?.onClick(data);

    onChange && onChange(e, data);
  };

  const renderNavigation = (data, index) => {
    const { title } = data;

    const onClick = data?.onClick;

    const isActive = (onChange || onClick) && index === state.index ? 'active' : '';

    return onChange || onClick ? (
      <div className={`nav__item`}>
        <span className={`nav__link ${isActive}`} onClick={(e) => onCustomChange(e, data, index)}>
          {data?.icon && data.icon}
          {title}
        </span>
      </div>
    ) : data?.target ? (
      <a data-testid={data?.path} href={data.path} target="_blank" rel="noreferrer" className={`nav__item`}>
        <div className={`nav__link`}>
          {data?.icon && data.icon}
          {title}
        </div>
      </a>
    ) : (
      <NavLink data-testid={data?.path} className={`nav__item`} onClick={toggleButton} to={data?.path ?? '#'}>
        <div className={`nav__link`}>
          {data?.icon && data.icon}
          {title}
        </div>
      </NavLink>
    );
  };

  const renderItem = () => {
    return data?.map((item, i) => (
      <Permission is={item.permissions} allowedRoles={item?.allowedRoles} key={i}>
        {renderNavigation(item, i)}
      </Permission>
    ));
  };

  const classesNav = classNames('nav', {
    [`nav--${type}`]: type,
  });

  return <div className={classesNav}>{renderItem()}</div>;
};

export default Nav;
