import React from 'react';

interface IProps {
  className?: string;
}

export default function ELCloseCircle(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="none" viewBox="0 0 24 24" {...props}>
      <circle cx="12" cy="12" r="11" stroke="currentColor" strokeWidth="2" />
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeWidth="2"
        d="M15.65 8.347l-7.303 7.304m7.303-.001L8.348 8.348"
      />
    </svg>
  );
}
