export default {
  RU: {
    'status.pending': 'Приглашен',
    'status.rejected': 'Отклонено',
    'status.accepted': 'Принято',
    'status.statistic.Completed': 'Завершен',
    'status.statistic.InProgress': 'В прогрессе',
    'g.canceled': 'Отменено',
    'g.payment': 'Оплачено',
    'g.pending': 'Ожидание',
    'g.fao': 'Русский (Ru)',
    'g.forbidden': 'Запрещено',
    'g.allowed': 'Разрешено',
    'g.unlimited': 'Неограниченно',
    'g.accidentally': 'Случайно',
    'g.consistently': 'Последовательно',
    'g.teach': 'Обучаю',
    'g.studying': 'Учусь',
    'g.available': 'Доступны',
    'g.available.not': 'Недоступны',
    'g.sts.rejected.1': 'Отклонена',
    'g.sts.payment_error': 'Ошибка оплаты',
    'g.sts.pay.not': 'Не оплачено',
    'g.sts.active.s': 'Активные',
    'g.sts.completed': 'Выполнено',
    'g.sts.not.yet': 'Не начал',
    'g.sts.not.yet.s': 'Не начали',
    'g.sts.cpl.end': 'Изучил',
    'g.sts.cpl.end.s': 'Изучили',
    'g.sts.cpl.now': 'Изучают',
    'g.sts.cpl': 'Изучает',
    'g.sts.fulfilled': 'Выполнил',
    'g.sts.comply.not': 'Не выполнил',
    'g.sts.start.not': 'Не приступал',
    'g.gb': 'Гб',
    'g.month': 'месяц',
    'g.month.s': 'месяца',
    'g.months': 'месяцев',
    'g.members': 'Участники',
    'g.gigabytes': 'Гигабайты',
    'g.period': 'Период',
    'g.month.m': 'мес',
    'g.year': 'год',
    'g.amount': 'Сумма',
    'g.languages': 'Языки',
    'g.name': 'Название',
    'g.description': 'Описание',
    'g.cover': 'Обложка',
    'g.cover.mini': 'Миниатюра',
    'g.from': 'от',
    'g.to': 'до',
    'g.point.a': 'Баллы',
    'g.question': 'вопрос',
    'g.question.s': 'вопроса',
    'g.questions': 'вопросов',
    'g.minute': 'минута',
    'g.minute.s': 'минуты',
    'g.minutes': 'минут',
    'g.point': 'балл',
    'g.point.s': 'балла',
    'g.points': 'баллов',
    'g.attempt': 'Попытка',
    'g.attempt.s': 'попытки',
    'g.attempts': 'попыток',
    'g.day': 'день',
    'g.days.s': 'дня',
    'g.days': 'дней',
    'g.domain': 'Домен',
    'g.NDS': 'НДС',
    'g.NDS.not': 'Без НДС',
    'g.dates': 'Даты',
    'g.author': 'Автор',
    'g.mmb': 'Пользователи',
    'g.usr.s': 'Участники',
    'g.event': 'Мероприятие',
    'g.settings': 'Настройки',
    'g.content': 'Содержание',
    'g.site': 'Сайт',
    'g.paid': 'Платно',
    'g.free': 'Бесплатно',
    'g.limited.not': 'Неограничено',
    'g.money.ru': 'руб.',
    'g.user': 'Пользователь',
    'g.email': 'E-mail',
    'g.search': 'Поиск',
    'g.pgn.view': 'Показывать на странице по',
    'g.pgn.lbl': 'Перейти на',
    'g.sts.pay.wait': 'Ожидает оплаты',
    'g.sts.pay.scc': 'Оплачено',
    'g.sts': 'Статус',
    'g.role': 'Роль',
    'g.usr.clock': 'Пользователь заблокирован',
    'g.choose': 'выбран',
    'g.usr': 'Участник',
    'g.ivt.tlt': 'Приглашения',
    'g.progress': 'Прогресс',
    'g.search.pls': 'Введите текст',
    'g.middle': 'Среднее',
    'g.usr.all': 'Все участники',
    'g.download.s': 'Скачивание',
    'g.btn.cancel': 'Отмена',
    'g.btn.cancel.s': 'Отменить',
    'g.btn.send': 'Отправить',
    'g.btn.pay': 'Оплатить',
    'g.btn.yes': 'Да',
    'g.btn.no': 'Нет',
    'g.btn.delete': 'Удалить',
    'g.btn.save': 'Сохранить',
    'g.btn.create': 'Создать',
    'g.btn.restore': 'Восстановить',
    'g.btn.activate': 'Активировать',
    'g.btn.move.archive': 'Поместить в архив',
    'g.btn.stop': 'Остановить',
    'g.btn.canceled': 'Отклонить',
    'g.btn.confirmed': 'Подтвердить',
    'g.btn.ivt': 'Пригласить',
    'g.btn.add': 'Добавить',
    'g.btn.ext.excel': 'Выгрузка в Excel',
    'g.btn.view.more': 'Показать ещё',
    'g.btn.upload.new.s': 'Загрузить новый',
    'g.btn.upload': 'Загрузить',
    'g.btn.back': 'Назад',
    'g.btn.overwrite': 'Перезаписать',
    'g.btn.copy': 'Скопировать',
    'g.btn.accept': 'Принять',
    'g.btn.verify': 'Проверить',
    'g.btn.change': 'Изменить',
    'g.btn.change.not': 'Не изменять',
    'g.btn.awr': 'Ответить',
    'g.study.mode': 'Режим Учусь',
    'g.teaching.mode': 'Режим Обучаю',
    'g.my.profile': 'Мой профиль',
    'g.my.agency': 'Мое агентство',
    'g.change.agency': 'Сменить агенство',
    'g.exit': 'Выйти',
    'g.shared.info': 'Общая информация',
    'g.usr.not.found': 'Пользователей не найдено',
    'g.opt.ivt.cancel': 'Отменить приглашение',
    'g.opt.ivt.repeat': 'Пригласить повторно',
    'g.opt.unlock': 'Разблокировать',
    'g.opt.lock': 'Заблокировать',
    'g.opt.delete.usr': 'Удалить пользователя',
    'g.date.actives': 'Активность',
    'g.ivt.role': 'Пригласить на роль',
    'g.fld.email.pls': 'Введите e-mail',
    'g.fld.last.name.pls': 'Введите Фамилию',
    'g.fld.first.name.pls': 'Введите Имя',
    'g.fld.middle.name.pls': 'Введите Отчество',
    'g.fld.last.name': 'Фамилия',
    'g.fld.first.name': 'Имя',
    'g.fld.middle.name': 'Отчество',
    'g.usr.s.delete': 'Хотите удалить выбраных пользователей?',
    'g.usr.s.unlock': 'Хотите разблокировать выбраных пользователей?',
    'g.usr.s.lock': 'Хотите заблокировать выбраных пользователей?',
    'g.tbl.opt.lbl': 'Задания, отображаемые в таблице',
    'g.search.tst': 'Найти задание',
    'g.video.instruction': 'Видеоинструкция',
    'g.support': 'Техподдержка',
    'g.materials': 'Материалы',
    'g.materials.s': 'Материалов',
    'g.multimedia': 'Мультимедиа',
    'g.task': 'Задание',
    'g.task.s': 'Заданий',
    'g.online.lesson': 'Онлайн-урок',
    'g.file': 'Файл',
    'g.book': 'Книга',
    'g.page': 'Страница',
    'g.image': 'Изображение',
    'g.video': 'Видео',
    'g.audio': 'Аудио',
    'g.test': 'Тест',
    'g.homework': 'Домашнее задание',
    'g.broadcast': 'Трансляция',
    'g.load.file.s': 'Загрузите файл',
    'g.time': 'Время',
    'g.createdAt': 'Создание',
    'g.access': 'Доступ',
    'g.add.link': 'Вставить ссылку',
    'g.embed.code': 'Встройте код',
    'g.embed.code.vide': 'Встройте код видео',
    'g.date': 'Дата',
    'g.duration': 'Длительность',
    'g.min.t': 'мин.',
    'g.from.s': 'из',
    'g.copy': 'Копия',
    'g.time.sent': 'Отправленно в',
    'g.read.more': 'Читать далее',
    'g.collapse': 'Свернуть',
    'g.btn.ath.file': 'Прикрепить файл',
    'g.comments': 'Комментарии',
    'g.comments.etr': 'Введите комментарий',
    'g.comments.del': 'Удалить комментарий',
    'g.comments.s': 'комментариях',
    'g.date.time': 'Дата и время',
    'g.min': 'минимум',
    'g.max': 'максимум',
    'g.pass': 'проходной',
    'g.other': 'Другое',
    'g.change.s': 'Сменить',
    'g.questions.s': 'Вопросы',
    'g.active.not': 'Неактивное',
    'g.active': 'Активное',
    'g.file.new': 'Новый файл',
    'g.choose.s': 'Выбрать',
    'g.download': 'Скачать',
    'g.btn.open': 'Открыть',
    'g.date.chg': 'Дата изменения',
    'g.size': 'Размер',
    'g.date.s': 'Даты проведения',
    'g.or': 'или',
    'g.register': 'зарегистрироваться',
    'g.btn.participate': 'Участвовать',
    'g.material': 'Материал',
    'g.end': 'Завершить',
    'g.cancel': 'Отклонен',
    'g.webinar': 'Вебинар',
    'g.sing.in.tlt': 'Вход',
    'g.sing.in.pass': 'Пароль',
    'g.sing.in.pass.save': 'Запомнить пароль',
    'g.sing.in.forgot': 'Забыли пароль?',
    'g.sing.in.confirm.tlt': 'При входе вы даете согласие на обработку персональных данных.',
    'g.sing.in': 'Войти',
    'g.sing.up': 'Зарегистрироваться',
    'g.sing.up.confirm.tlt': 'Регистрируясь, вы даете согласие на обработку персональных данных.',
    'g.sing.in.me': 'У меня уже есть аккаунт',
    'g.sing.forgot.email': 'Введите ваш E-mail, и мы вышлем ссылку на восстановление пароля',
    'g.sing.forgot': 'Восстановить пароль',
    'g.sing.forgot.up': 'Я вспомнил пароль',
    'g.sing.forgot.link': 'Вам отправлена ссылка для восстановления пароля',
    'g.sing.forgot.check.mail': 'Проверьте почту',
    'g.sing.forgot.check.mail.link': 'ссылка для восстановления уже там',
    'g.sing.forgot.thanks': 'Спасибо, посмотрю!',
    'g.dates.time': 'Выберите дату и время',
    'g.connect': 'Подключиться',
    'g.reconnect': 'Переподключиться',
    'g.back.page': 'Покинуть страничку',
    'g.ivt': 'Приглашение',
    'g.ivt.prt': 'к участию',
    'g.ivt.prt.cancel': 'Отклонить пригашение',
    'g.ivt.prt.confirm': 'Принять приглашение',
    'g.ivt.prt.cancel.time': 'Приглашение будет отклонено черезе:',
    'g.seconds': 'сек.',
    'g.continue': 'Продолжить',
    'g.control.role': 'к управлению в роли',
    'g.join': 'Присоединиться',
    'g.see': 'Просмотр',
    'g.statistic': 'Статистика',
    'g.roles': 'Роли',
    'g.not.found': 'Hе найдено',
    'g.section': 'Раздел',
  },
};
