import React, { FC, ReactNode, useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Icon from '../../components/Icon';
import Text from '../../components/Text';
import { CONFIG } from '../../env';
import { useRouter, useToState } from '../../hooks';
import { LOCAL } from '../../i18n';
import SocketClient from '../../providers/SocketClient';
import { getRoute } from '../../routes/client';
import { IReduxStore } from '../../store/serviceReducer';
import { Button, Layout } from '../app.modules';
import { setLangName } from '../Location/location.actions';
import { ILocationName } from '../Location/location.resources';
import Preloader from '../Preloader';
import { IProfileUser } from '../Profile/profile.resources';
import {
  middlewareForceUpdate,
  middlewareSession,
  middlewareSetRoles,
  middlewareSetUser,
  middlewareSetUserRole,
  middlewareSocketStream,
} from './middleware.actions';
import { IMiddlewareForceUpdate } from './middleware.resources';
import { middlewareGetRoles, middlewareGetUserRole, middlewareUserRead } from './middleware.services';
import { typeAuth, typeRedirect } from './middleware.types';
import MiddlewareGuard from './MiddlewareGuard';

interface IProps {
  children: any;
  session: any | null;
  user?: IProfileUser;
  middlewareUserReadAction: any;
  middlewareSessionAction: any;
  middlewareSetUserAction: any;
  middlewareGetUserRoleAction: any;
  middlewareGetRolesAction: any;
  middlewareSetUserRoleAction: any;
  middlewareSetRolesAction: any;
  middlewareSocketStreamAction: any;
  socketStream: any;
  forceUpdate: IMiddlewareForceUpdate;
  middlewareForceUpdateAction(forceUpdate: IMiddlewareForceUpdate): void;
  exception: ReactNode;
  setLangNameAction(name: ILocationName): void;
}

const Middleware: FC<IProps> = ({
  session,
  children,
  middlewareUserReadAction,
  middlewareSessionAction,
  middlewareSetUserAction,
  user,
  middlewareGetUserRoleAction,
  middlewareGetRolesAction,
  middlewareSetUserRoleAction,
  middlewareSetRolesAction,
  middlewareSocketStreamAction,
  socketStream,
  forceUpdate,
  middlewareForceUpdateAction,
  exception,
  setLangNameAction,
}) => {
  const { history, pathname } = useRouter();

  const { IGNORE, SKIP, EXCEPTION } = typeAuth;

  const { ADMIN, USER, SUPER_ADMIN } = typeRedirect;

  const [state, setState] = useToState({
    loading: true,
    typeRenderCatch: IGNORE,
    forceUpdate: false,
  });

  const FORCES__FlowDistributor = (data = user) => {
    const domain: any = /:\/\/([^\/]+)/.exec(window.location.href);

    const searchSubDomain = domain[1]?.split('.');

    if (searchSubDomain[0] === CONFIG.REACT_APP_API_DOMAIN_EXCEPTION && CONFIG.REACT_APP_SENTRY_ACTIVATE) {
      return EXCEPTION;
    }

    return data?.agency && searchSubDomain[0] !== data?.agency?.subdomain && CONFIG.REACT_APP_SENTRY_ACTIVATE;
  }; //TODO [Work]: Will need work on types

  const SERVER_CONNECT__parser = (Fn) => {
    if (session?.connect === '404') {
      return [
        () => (
          <Layout>
            <div className="app__ew">
              <Icon className="app__ew-icon" name="EW" />
              <Text className="app__ew-title">
                <strong>Технические работы</strong>
              </Text>
              <Button
                size="sm"
                className="app__ew-btn"
                onClick={() => {
                  document.location.replace('/');
                }}
              >
                Вернуться
              </Button>
            </div>
          </Layout>
        ),
      ];
    }

    return Fn();
  }; //TODO [See]: Micro-Software for errors and ads

  const onForceUpdate = () => {
    setState({
      forceUpdate: !state.forceUpdate,
    });
  };

  const STREAMING__OpenedRoutes = () => {
    if (FORCES__FlowDistributor() === EXCEPTION) {
      return exception;
    }

    const contentStream = session ? children : <MiddlewareGuard redirect={false} onUpdate={onForceUpdate} />;

    // if (pathname.includes(getRoute('C', 'path'))) {
    //   return children;
    // } //TODO [Rework--//--Thunk]: Will be do handler for dynamic open rout's

    switch (pathname) {
      case getRoute('MC/I', 'path')('participant'):
      case getRoute('S/C', 'path', { full: true }):
        return children;
      default:
        return contentStream;
    }
  };

  const USER__dist = () => {
    if (session && user && FORCES__FlowDistributor() === true) return null; //TODO [TSN]: Temporary Solution

    const types = {
      [IGNORE]: [STREAMING__OpenedRoutes],
      [SKIP]: [() => children],
    };

    const [isToLogin] = (() => {
      switch (pathname) {
        case getRoute('AL', 'path'):
        case getRoute('AS', 'path'):
        case getRoute('AF', 'path'):
        case getRoute('AFS', 'path'):
        case getRoute('ARP', 'path'):
          return SERVER_CONNECT__parser(() => types[SKIP]);
        default:
          return SERVER_CONNECT__parser(() => types[IGNORE]);
      }
    })();

    return isToLogin && isToLogin();
  };

  const USER__redirect = (data) => {
    // console.log(FORCES__FlowDistributor(), '[Test]: Connect { EXCEPTION }')
    if (FORCES__FlowDistributor() === EXCEPTION) return;

    const isRedirect = data?.super ? SUPER_ADMIN : data?.agency !== null ? ADMIN : USER;

    const types = {
      [SUPER_ADMIN]: getRoute('AHP', 'path', { full: true }),
      [ADMIN]: getRoute('AP', 'path', { full: true }),
      [USER]: getRoute('DP', 'path', { full: true }),
    };

    history.push(types[isRedirect]);
  };

  const USER__checkingAgencyPermission = () => {
    const lockUserRoute = pathname.includes(getRoute('AP', 'path', { full: true }));

    if (lockUserRoute) {
      history.push(getRoute('DP', 'path', { full: true }));
    }
  };

  const USER__checkingSuperAdminPermission = () => {
    const lockUserRoute = pathname.includes(getRoute('AHP', 'path', { full: true }));

    if (lockUserRoute) {
      history.push('/');
    }
  };

  const declareTheme = (data) => {
    const html = document.querySelector('html');

    Object.keys(data?.theme).forEach((key) => {
      const value = data?.theme[key];

      html?.setAttribute(`data-${key}`, value);
    });
  };

  const declareLocation = (data) => {
    const defaultLocalName = Intl.DateTimeFormat().resolvedOptions().locale.toUpperCase();

    const validationName = defaultLocalName in LOCAL;

    setLangNameAction(data.lang ?? (validationName ? defaultLocalName : 'RU')); //TODO [TSN]: Temporary Solution
  };

  const declareUser = (data) => {
    middlewareSetUserAction(data);
  };

  const declareSession = (data) => {
    const validationAdmin = data?.super ? SUPER_ADMIN : data?.agency !== null ? ADMIN : USER;

    middlewareSessionAction({
      connect: true,
      type: validationAdmin, //TYPES.USER,
    }); //use instead localStorage
  };

  const declareSockets = () => {
    const socket = new SocketClient({
      host: `${CONFIG.REACT_APP_API_URL}`,
      options: { withCredentials: true }, //global auth for sockets
    });

    socket.createEvent('createRoom');

    middlewareSocketStreamAction(socket);
  };

  const declareRoles = (data) => {
    if (data.agency) {
      middlewareGetUserRoleAction().then((response) => {
        middlewareSetUserRoleAction(response);
      });
      middlewareGetRolesAction().then((response) => {
        middlewareSetRolesAction(response);
      });
    }
  };

  const declareHomeSubRoute = (data) => {
    if (FORCES__FlowDistributor(data) === true) {
      //TODO [Rework]: Rework types
      window.location.replace(`https://${data?.agency?.subdomain}.edbee.ru`);
    } //TODO [TSN]: Temporary Solution
  };

  const declareVersion = () => {
    // eslint-disable-next-line no-console
    console.info('%cVersion: 0.1.79', 'color: #fdc63b;  font-size: 21px');
  };

  const declareModes = (data) => {
    declareHomeSubRoute(data);
    declareTheme(data);
    declareUser(data);
    declareLocation(data);
    declareSession(data);
    declareRoles(data);
    declareSockets();
    declareVersion();
  };

  const USER__onRepeatLoad = () => {
    // setState({
    //   loading: true,
    // });//TODO [Thunk]: Why this now need?

    middlewareUserReadAction()
      .then((data) => {
        declareHomeSubRoute(data);
        declareUser(data);
        declareLocation(data);
        declareRoles(data);

        if (!session) {
          declareSession(data); //TODO[Thunk]: Think about whether update session and why not update all declarations
        }

        if (!socketStream) {
          declareSockets();
        }

        forceUpdate && forceUpdate();

        middlewareForceUpdateAction(null);

        // setState({
        //   loading: false,
        // });//TODO [Thunk]: Why this now need?
      })
      .catch(() => {
        // setState({
        //   loading: false,
        // });//TODO [Thunk]: Why this now need?
        socketStream?.closeConnect(); //TODO [TEST]
      });
  };

  const onForceParser = () => {
    if (forceUpdate !== null) {
      USER__onRepeatLoad();
    }
  };

  const USER__onLoad = () => {
    setState({
      loading: true,
    });

    !user &&
      middlewareUserReadAction()
        .then((response) => {
          declareModes(response);

          setState({
            loading: false,
          });
        })
        .catch(() => {
          setState({
            loading: false,
          });
          socketStream?.closeConnect(); //TODO [TEST]
        });
  };

  const onLoadParser = () => {
    if (session?.connect === '404') {
      // When 404
      return;
    }

    if (session && user && pathname === '/') {
      // When root loading
      USER__redirect(user);
    }

    if (session && user && pathname !== '/' && user?.agency === null) {
      // When test on agency
      USER__checkingAgencyPermission();
    }

    if (session && user && pathname !== '/' && !user?.super) {
      // When test on super admin
      USER__checkingSuperAdminPermission();
    }

    if (session && user && FORCES__FlowDistributor() === true) return;

    if (!session && !user && state.forceUpdate) {
      // When node is skipped
      USER__onLoad();
    }

    if ((!session || !user) && !state.forceUpdate) {
      // When routing
      USER__onLoad();
    }
  };

  useEffect(onLoadParser, [session, pathname, state.forceUpdate]);

  useEffect(onForceParser, [forceUpdate]);

  const renderTypesDefender = () => {
    const { loading } = state;

    if (loading) return <Preloader />; //TODO [Test]: Why need this?

    return USER__dist();
  };

  return useMemo(renderTypesDefender, [session, state.loading, pathname, forceUpdate]);
};

const mapStateToProps = (store: IReduxStore) => ({
  session: store.middleware.session!,
  user: store.middleware.user!,
  socketStream: store.middleware.socketStream!,
  forceUpdate: store.middleware.forceUpdate!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  middlewareUserReadAction: bindActionCreators(middlewareUserRead, dispatch),
  middlewareSetUserAction: bindActionCreators(middlewareSetUser, dispatch),
  middlewareSessionAction: bindActionCreators(middlewareSession, dispatch),
  middlewareGetUserRoleAction: bindActionCreators(middlewareGetUserRole, dispatch),
  middlewareGetRolesAction: bindActionCreators(middlewareGetRoles, dispatch),
  middlewareSetUserRoleAction: bindActionCreators(middlewareSetUserRole, dispatch),
  middlewareSetRolesAction: bindActionCreators(middlewareSetRoles, dispatch),
  middlewareSocketStreamAction: bindActionCreators(middlewareSocketStream, dispatch),
  middlewareForceUpdateAction: bindActionCreators(middlewareForceUpdate, dispatch),
  setLangNameAction: bindActionCreators(setLangName, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Middleware);
