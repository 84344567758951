import React, { FC, ReactElement } from 'react';
import types from './types';

interface IProps {
  name?: string;
  className?: string;
}

const Loader: FC<IProps> = function ({ name = 'Mount', className = '' }): ReactElement {
  return types[name]({ className });
};

export default Loader;
