import './index.scss';
import React, { FC, ReactElement } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useToState } from '../../../../../hooks';
import translate from '../../../../../i18n/translate';
import { IReduxStore } from '../../../../../store/serviceReducer';
import { dataParseBndReverse, uuid } from '../../../../../utils';
import { BDN, Button, EditorText, Icon, Text, Toggle, TQuestion } from '../../../../app.modules';
import { setTemplate } from '../../../builder.questions.actions';
import { questionCreateAnswer, questionDeleteAnswer } from '../../../builder.questions.services';
import CreateAttach from '../../CreateAttach';
import CreateHeading from '../../CreateHeading';
import Scores from '../../CreateScores';
import QuestionUpper from '../../CreateUpper';
import Bridge from '../../questions.create.pipes';
import QuestionService from '../questions.types.services';
import ChoiceAnswer from './ChoiceAnswer';

interface IProps {
  data: any;
  template?: any;
  setTemplateAction?: any;
  questionCreateAnswerAction?: any;
  questionDeleteAnswerAction?: any;
}

const Choice: FC<IProps> = ({
  data,
  template,
  setTemplateAction,
  questionCreateAnswerAction,
  questionDeleteAnswerAction,
}): ReactElement => {
  const [state, setState] = useToState({
    scoreCompleteValue: data?.typeFields?.score || '',
  });

  const findBlock = () => [[...template], [...template[data.indexUp].data]];

  const onAddAnswer = () => {
    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const cloneAnswersData = [...cloneBlock[data.typeAnswer].data];

    const searchPosition = cloneAnswersData.length ? cloneAnswersData.length - 1 : 0;

    questionCreateAnswerAction(questions[data.indexDown].id).then((response) => {
      const cloneAnswers = [
        ...cloneAnswersData,
        {
          ...Bridge.getTypeDataAnswer(data.type, {}, searchPosition + 1), //TODO [Thunk]: position -> test
          id: response.id,
          uuid: uuid(),
        },
      ];

      const newCloneAnswers = {
        ...cloneBlock,
        [data.typeAnswer]: {
          ...cloneBlock[data.typeAnswer],
          data: cloneAnswers,
        },
      };

      questions[data.indexDown] = {
        ...newCloneAnswers,
        ...Bridge.onErrorChange(newCloneAnswers),
      };

      part[data.indexUp].data = questions;

      setTemplateAction(part);
    });
  };

  const onChangeAnswer = (item, field) => {
    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const cloneAnswers = [...cloneBlock[data.typeAnswer].data];

    const answer = cloneAnswers[item.indexDown];

    cloneAnswers[item.indexDown] = {
      ...answer,
      ...field,
    };

    const newCloneBlock = {
      ...cloneBlock,
      [data.typeAnswer]: {
        ...cloneBlock[data.typeAnswer],
        data: cloneAnswers,
      },
    };

    questions[data.indexDown] = {
      ...newCloneBlock,
      ...Bridge.onErrorChange(newCloneBlock),
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);
  };

  const onDeleteAnswer = (item) => {
    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const cloneAnswers = [...cloneBlock[data.typeAnswer].data];

    cloneAnswers.splice(item.indexDown, 1);

    const newCloneAnswers = {
      ...cloneBlock,
      [data.typeAnswer]: {
        ...cloneBlock[data.typeAnswer],
        data: cloneAnswers,
      },
    };

    questions[data.indexDown] = {
      ...newCloneAnswers,
      ...Bridge.onErrorChange(newCloneAnswers),
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);

    questionDeleteAnswerAction(`${questions[data.indexDown].id}/${item.id}`); //TODO [Thunk]
  };

  const onChangeTemplate = (e) => {
    const [part, questions] = findBlock();

    const sortTemplateAnswer = dataParseBndReverse(e);

    questions[data.indexDown] = {
      ...questions[data.indexDown],
      [data.typeAnswer]: {
        ...questions[data.indexDown][data.typeAnswer],
        data: sortTemplateAnswer[data.indexUp].data.map((el, i) => ({ ...el, position: i })),
      },
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);
  };

  const onChangeScoreComplete = (e, fn) => {
    const { value } = e.target;

    const isValid = value.replace(/\D/g, '');

    setState({
      scoreCompleteValue: isValid,
    });

    fn({ target: { checked: +isValid } }, `score`, 'checkbox');
  };

  const onChangeFieldUnderType = (fn, data: any, key) => (e) => {
    fn(e);

    data[data.typeAnswer].data.length === 2 && key === 'multipleAnswers' && e.target.checked && onAddAnswer();
  };

  const isErrorBody = Bridge.parseBodyError(data);

  const isErrorScoreComplete = Bridge.parseScoreCompleteError(data);

  return (
    <QuestionService data={data}>
      {({ data, onChangeFieldName, onChangeFieldUnder, onSaveQuestion, onChangeFieldAudio }) => (
        <TQuestion className="choice">
          <TQuestion.Head>
            <CreateHeading number={data.indexDown + 1} name={data?.name || data?.body} />
            <QuestionUpper data={data} onChange={onChangeFieldName} onChangeFieldAudio={onChangeFieldAudio} />
          </TQuestion.Head>
          <TQuestion.Body>
            <EditorText error={false} data={data.body} onChange={(e) => onChangeFieldName(e, 'body')} />
            <CreateAttach data={data} onChangeField={onChangeFieldName} onChangeFieldAudio={onChangeFieldAudio} />
          </TQuestion.Body>
          <TQuestion.Foot>
            <TQuestion.Choice>
              <Toggle
                variant="radio"
                type="radio"
                name={`multipleAnswers-${data.uuid}`}
                value={0}
                defaultChecked={!data?.typeFields?.multipleAnswers}
                onChange={(e) => onChangeFieldUnder(e, `multipleAnswers`, 'radio')}
              >
                <Text variant="lg">{translate('qs.once')}</Text>
              </Toggle>
              <Toggle
                variant="radio"
                type="radio"
                name={`multipleAnswers-${data.uuid}`}
                value={1}
                defaultChecked={data?.typeFields?.multipleAnswers}
                onChange={onChangeFieldUnderType(
                  (e) => onChangeFieldUnder(e, `multipleAnswers`, 'radio'),
                  data,
                  'multipleAnswers'
                )}
              >
                <Text variant="lg">{translate('qs.more')}</Text>
              </Toggle>
              {data?.typeFields?.multipleAnswers && (
                <Toggle
                  variant="primary"
                  type="checkbox"
                  name={`countIncomplete-${data.uuid}`}
                  value={false}
                  defaultChecked={data?.typeFields?.countIncomplete}
                  onChange={(e) => onChangeFieldUnder(e, `countIncomplete`, 'checkbox')}
                >
                  <Text variant="lg">{translate('qs.more.up')}</Text>
                </Toggle>
              )}
              {data?.typeFields?.multipleAnswers && !data?.typeFields?.countIncomplete && (
                <Scores
                  errorText={translate('qs.err.scr.txt')}
                  error={isErrorScoreComplete}
                  data={state.scoreCompleteValue}
                  onChange={(e) => onChangeScoreComplete(e, onChangeFieldUnder)}
                />
              )}
            </TQuestion.Choice>
            <BDN data={[data[data.typeAnswer]]} variant="answer" onChange={onChangeTemplate}>
              {(status, handle) => (
                <ChoiceAnswer
                  isDelete={data[data.typeAnswer].data.length}
                  data={status}
                  question={data}
                  handle={handle}
                  onChange={onChangeAnswer}
                  onDelete={onDeleteAnswer}
                />
              )}
            </BDN>
            <div className="choice__addition">
              <Button size="md" variant="outlined" bold onClick={onAddAnswer}>
                <Icon name="Add" />
                {translate('qs.add.awr')}
              </Button>
            </div>
            <div className="choice__bot">
              <Button off={data.error} variant="primary" size="md" onClick={onSaveQuestion}>
                {translate('qs.save')}
              </Button>
            </div>
          </TQuestion.Foot>
        </TQuestion>
      )}
    </QuestionService>
  );
};

const mapStateToProps = (store: IReduxStore) => ({
  template: store.builderTests.template!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTemplateAction: bindActionCreators(setTemplate, dispatch),
  questionCreateAnswerAction: bindActionCreators(questionCreateAnswer, dispatch),
  questionDeleteAnswerAction: bindActionCreators(questionDeleteAnswer, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Choice);
