import './index.scss';
import React, { FC, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  className?: string;
  swipe?: boolean;
}
interface IParts {
  children?: ReactNode;
  className?: string;
}

const field = {
  app: 't--question',
  head: 't--question__head',
  body: 't--question__body',
  foot: 't--question__foot',
  choice: 't--question__choice',
  free: 't--question__free',
};

// Универсальный компонент для рендеринга частей
const LayoutPart: FC<IParts> = ({ children, className }) => <div className={className}>{children}</div>;

export const Head: FC<IProps> = ({ children }) => <LayoutPart className={field.head}>{children}</LayoutPart>;

export const Body: FC<IProps> = ({ children }) => <LayoutPart className={field.body}>{children}</LayoutPart>;

export const Foot: FC<IProps> = ({ children }) => <LayoutPart className={field.foot}>{children}</LayoutPart>;

export const Choice: FC<IProps> = ({ children }) => <LayoutPart className={field.choice}>{children}</LayoutPart>;

export const Free: FC<IProps> = ({ children }) => <LayoutPart className={field.free}>{children}</LayoutPart>;

const TQuestion: FC<IProps> & {
  Head: typeof Head;
  Body: typeof Body;
  Foot: typeof Foot;
  Choice: typeof Choice;
  Free: typeof Free;
} = ({ children, className = '' }) => {
  return <div className={`${field.app} ${className}`}>{children}</div>;
};

// Добавляем дочерние компоненты как свойства TQuestion
TQuestion.Head = Head;
TQuestion.Body = Body;
TQuestion.Foot = Foot;
TQuestion.Choice = Choice;
TQuestion.Free = Free;

export default TQuestion;
