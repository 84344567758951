import './index.scss';
import React, { FC, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { useErrorFields, useRouter, useToState } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { getRoute } from '../../../../routes/client';
import { uuid } from '../../../../utils';
import { setAlert } from '../../../app.actions';
import { Button, Input, Textarea } from '../../../app.modules';
import { groupCreate } from '../../../Groups/groups.services';

interface IProps {
  groupCreateAction: any;
  setAlertAction: any;
}

const CreateGroup: FC<IProps> = ({ groupCreateAction, setAlertAction }) => {
  const [state, setState] = useToState({
    name: '',
    description: '',
  });

  const { history } = useRouter();

  const [test, setTest] = useState(null);

  const elemRef: any = useRef(null);

  useEffect(() => {
    setTest(test);
  }, [elemRef]);

  useEffect(() => {
    setTest(elemRef);
    elemRef?.current?.focus();
  }, [test]); //TODO [это пиздец]: переделать (почемуто непроисходит обновления компонента - приходиться обновлять принудительно)

  const [error, setError] = useErrorFields(state, (value, key) => {
    switch (key) {
      case 'name':
        return value?.length;
      default:
        return true;
    }
  });

  const onChangeField = (key) => (e) => {
    const value = e?.target?.value ?? e;

    setError(key, value);

    setState({
      [key]: value,
    });
  };

  const onConfirmData = () => {
    setError(state).then(() => {
      groupCreateAction(state).then((response) => {
        history.push(getRoute('AP/G/-/S', 'path', { full: true })(response.id));
        setAlertAction({
          code: '080701',
          uuid: uuid(),
          params: {},
        });
      });
    });
  };

  const onClose = () => {
    history.goBack();
  };

  return (
    <div className="create-event">
      <h1 className="create-event__head">Новая Группа</h1>
      <div className="create-event__row">
        <Input
          innerRef={elemRef}
          placeholder="Введите название группы"
          label="Название"
          size="md"
          value={state.name}
          onChange={onChangeField('name')}
          error={!error.name}
        />
      </div>
      <div className="create-event__row">
        <Textarea
          placeholder="Введите описание группы"
          label="Описание"
          value={state.description}
          onChange={onChangeField('description')}
        />
      </div>
      <div className="create-event__foot">
        <Button size="md" variant="outlined" monochrome onClick={onClose}>
          {translate('g.btn.cancel')}
        </Button>
        <Button off={!error.name} size="md" onClick={onConfirmData}>
          Создать
        </Button>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
  groupCreateAction: bindActionCreators(groupCreate, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(null, mapDispatchToProps)(CreateGroup);
