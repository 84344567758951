import React from 'react';
import { useEnvironment } from '../../shared/environment';
import { DEFAULT_ENVIRONMENT } from '../../shared/environment/config';
import LogoBase from '../LogoBase';
import LogoPochet from '../LogoPochet';
interface IProps {
  active?: boolean;
  className?: string;
}

const Logo: React.FC<IProps> = ({ active, className }) => {
  const environment = useEnvironment();

  return (
    <div>
      {environment.environment === DEFAULT_ENVIRONMENT ? (
        <LogoBase className={className} active={active} />
      ) : (
        <LogoPochet />
      )}
    </div>
  );
};

export default Logo;
