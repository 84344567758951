import React from 'react';

interface IProps {
  className?: string;
  onClick?: any;
}

export default function ELAddLightCirclePochet(props: IProps) {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <circle cx="9.99988" cy="10.0009" r="9.00085" stroke="currentColor" strokeWidth="2" />
      <path d="M5.9375 10H14.0632" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
      <path d="M10 5.78125L10 14.2195" stroke="currentColor" strokeWidth="2" strokeLinecap="round" />
    </svg>
  );
}
