import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect, useMemo, useRef } from 'react';
import {
  Button,
  Icon,
  Pagination,
  Permission,
  Restrictions,
  Scroll,
  Search,
  Square,
  Text,
} from '../../app/app.modules';
import { useMobile, useToState } from '../../hooks';
import translate from '../../i18n/translate';
import types, { cardFields } from './card.types';
import CardBlock from './CardBlock';
import CardMobile from './CardMobile';
import CardUser from './CardUser';

interface IProps {
  data: {
    column?: Array<{
      [key: string]:
        | any
        | Array<
            | any
            | {
                sort: boolean;
                check: boolean;
              }
          >;
    }>;
    row?: Array<{
      [key: string]: any;
    }>;
    thead?: {
      params?: {
        size?: 'md';
        sticky?: boolean;
      };
    };
    options?: any;
    optionsPermissions?: any;
    startPanel?: {
      search?:
        | any
        | {
            handler: any;
            placeholder: string;
          };
      link?: string | ReactNode;
      params?: {
        size: string;
      };
    };
    endPanel?: {
      pagination?: any;
    };
  };
  withScroll?: boolean;
  type?: string;
  empty?: {
    text?: string | ReactNode;
  };
}

const Card: FC<IProps> = ({ data, empty = null, withScroll = false, type = types.ADMIN_CARD }): ReactElement => {
  const [isMobile] = useMobile();

  const [state, setState] = useToState({
    tHead: [],
    tBody: [],
    sample: [],
    sampleAll: false,
    activeSortType: '',
    startPanel: null,
    endPanel: null,
    scrollRef: null,
  });

  const scrollRef = useRef(null) as any;

  const dataCardField = Object.values(cardFields);

  const sampleInjectFunction = (data) => {
    return dataCardField
      .map((el) =>
        typeof data[el] === 'function' && el !== 'lock'
          ? {
              ...data[el](),
              field: el,
              id: data.id,
              ...(data?.attach && {
                attach: data?.attach,
              }),
              ...(data?._onClick && {
                _onClick: data._onClick,
              }), //TODO [Thunk]: May be move in params {attach: {}, onClick: {} ...etc}
              ...(data?.params && {
                ...data?.params,
              }),
              // ...toLocked(data),
            }
          : null
      )
      .filter((el) => el)
      .sort((e, b) => e.position - b.position);
  };

  const THEAD_PARSER = (data) => {
    const injectElement = data[0] ?? []; //TODO [!!!]

    return sampleInjectFunction(injectElement);
  };

  const TBODY_PARSER = (data) => {
    return data?.map((el) => sampleInjectFunction(el));
  };

  const onLoadDefault = () => {
    const injectData = data?.column ?? data ?? [];

    const startPanel = data?.startPanel;

    const endPanel = data?.endPanel;

    setState({
      ...(data?.column &&
        !isMobile && {
          tHead: THEAD_PARSER(injectData),
        }),
      tBody: data?.row ? data.row : TBODY_PARSER(injectData),
      startPanel,
      endPanel,
    });
  };

  useEffect(onLoadDefault, [data]);

  const onToggleActiveSortType = (data) => () => {
    const askDesk = state.activeSortType === data.field;

    setState({
      activeSortType: askDesk ? '' : data.field,
    });

    data?.sort &&
      data.sort({
        sortBy: data.field,
        sortOrder: askDesk ? 'DESC' : 'ASC',
      });
  };

  const renderEmpty = () => {
    const text = empty?.text;

    return <Text className="card__empty">{text ? text : translate('g.not.found')}</Text>;
  };

  const renderHead = () => {
    const { tHead, tBody } = state;

    const size = data?.thead?.params?.size;

    const sticky = data?.thead?.params?.sticky ?? true;

    const classesThead = classNames('card__thead', {
      [`card__thead--${size}`]: size,
      [`card__thead--sticky`]: sticky,
    });

    return tHead?.length > 0 ? (
      <div className={classesThead}>
        {/*{renderPanel()}*/}
        {tHead.map((item, i) => (
          <Text
            key={i}
            className={classNames(`card__thead-th card__col--${item.field}`, {
              'card__thead-th--active': state.activeSortType === item.field,
            })}
          >
            <strong>{item.title}</strong>
            {item.sort && (
              <Square
                active={state.activeSortType === item.field}
                className="card__thead-sort"
                size="sm"
                variant="outlined"
                onClick={onToggleActiveSortType(item)}
              >
                <Icon name="ArrowSmall" />
              </Square>
            )}
          </Text>
        ))}
      </div>
    ) : tBody.length > 0 ? (
      false
    ) : null;
  };

  const renderBody = () => {
    const { tBody } = state;

    const typesBlocks = {
      [types.ADMIN_CARD]: () => {
        return !isMobile
          ? tBody?.map((el, i) => (
              <CardBlock
                key={el[0]?.id ?? i}
                data={el}
                options={data?.options}
                optionsPermissions={data?.optionsPermissions}
              />
            ))
          : tBody?.map((el, i) => (
              <CardMobile
                key={el[0]?.id ?? i}
                data={el}
                options={data?.options}
                optionsPermissions={data?.optionsPermissions}
              />
            ));
      },
      [types.USER_CARD]: () => {
        return tBody?.map((el, i) => <CardUser key={el[0]?.id ?? i} data={el} />);
      },
      [types.MOBILE_CARD]: () => {
        return tBody?.map((el, i) => (
          <CardMobile
            key={el[0]?.id ?? i}
            data={el}
            options={data?.options}
            optionsPermissions={data?.optionsPermissions}
          />
        ));
      },
    };

    return typesBlocks[type]();
  };

  const renderParsingSearch = (search, params) => {
    if (!search) return null;

    if (typeof search === 'function') {
      return (
        <Search onSearch={search} placeholderNode={translate('g.search.pls')} onEnter={search} size={params ?? 'md'} />
      );
    }

    return (
      <Search
        onSearch={search.handler}
        onEnter={search.handler}
        placeholderNode={search.placeholder}
        size={params ?? 'md'}
      />
    );
  };

  const renderParsingButton = (button) => {
    if (!button) return null;

    const replaceContent = ({ openModal, alert }) => {
      return (
        <Button size="md" variant="disabled" onClick={openModal} className="card__limited-btn">
          {button?.title}
          {alert && (
            <Text variant="xs" className="card__limited-text">
              {alert}
            </Text>
          )}
        </Button>
      );
    };

    return (
      <Permission is={button.permissions}>
        <Restrictions limited={button.restrictions} updateFollows={[button.restrictions[2]]} replace={replaceContent}>
          <Button className="card__start-panel__btn" size={button?.size} onClick={button?.handler}>
            {button?.title}
          </Button>
        </Restrictions>
      </Permission>
    );
  };

  const renderStartPanel = () => {
    const { startPanel } = state;

    const onSearch = startPanel?.search;

    const link = startPanel?.link;

    const button = startPanel?.button;

    const size = startPanel?.params?.size;

    const classesPanel = classNames('card__start-panel', {
      [`card__start-panel--${size}`]: size,
    });

    return (
      startPanel && (
        <>
          <div className={classesPanel}>
            {renderParsingSearch(onSearch, size)}
            {link && (
              <Text variant="md" className="card__start-panel__link" decoration="underline">
                {link}
              </Text>
            )}
            {renderParsingButton(button)}
          </div>
          {data.column!?.length || (!data.row!?.length && renderEmpty())}
        </>
      )
    );
  };

  const renderPagination = (pagination) => {
    const { tBody } = state;

    const paginationData = pagination?.data;

    const onChange = pagination?.onChange;

    const isShow = paginationData.total > paginationData.perPage;

    const isParams = {
      scrollIntoElement: withScroll && scrollRef?.current,
    };

    return isShow ? (
      <Pagination
        onChange={onChange}
        perPage={paginationData.perPage}
        page={paginationData.page}
        total={paginationData.total}
        realTimeCount={tBody.length}
        {...isParams}
      />
    ) : null;
  };

  const renderEndPanel = () => {
    const { endPanel } = state;

    const pagination = endPanel?.pagination;

    return endPanel && <div className="card__end-panel">{pagination && renderPagination(pagination)}</div>;
  };

  const renderCenterPanel = () => {
    const content = (
      <>
        {!isMobile && renderHead()}
        {renderBody()}
      </>
    );

    return withScroll ? (
      <div className="card__scrolled">
        <Scroll>
          <div className="card__scrolled-content">{content}</div>
        </Scroll>
      </div>
    ) : (
      content
    );
  };

  const classesCard = classNames('card', {
    'card--scroll': withScroll,
  });

  return useMemo(
    () => (
      <div className={classesCard} ref={scrollRef}>
        {renderStartPanel()}
        {renderCenterPanel()}
        {renderEndPanel()}
      </div>
    ),
    [data, state, isMobile]
  );
};

export default Card;
