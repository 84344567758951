import { createContext, useEffect, useState } from 'react';
import { DEFAULT_ENVIRONMENT } from './config';

export type Environment = Exclude<typeof process.env.REACT_APP_ENVIRONMENT, undefined>;

const getEnvironmentFromEnv = (): Environment => process.env.REACT_APP_ENVIRONMENT ?? DEFAULT_ENVIRONMENT;

export type EnvironmentContextType = {
  environment: Environment;
  setEnvironment: (Environment: Environment) => void;
};

export const EnvironmentContext = createContext<EnvironmentContextType | undefined>(undefined);

export const EnvironmentProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [environment, setEnvironment] = useState<Environment>(getEnvironmentFromEnv());

  useEffect(() => {
    document.documentElement.setAttribute('data-environment', environment);
  }, [environment]);

  return <EnvironmentContext.Provider value={{ environment, setEnvironment }}>{children}</EnvironmentContext.Provider>;
};
