import './assets/scss/root.scss';
import React, { lazy, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router';
import Preloader from './app/Preloader';
import { $root, modesActivate } from './env';
import ProviderINTL from './i18n/provider';
import { getRoute } from './routes/client';
import * as serviceWorker from './serviceWorker';
import { store } from './store/configureStore';

const AdminPanel = lazy(() => import('./app/AdminPanel'));

const Site = lazy(() => import('./app/Site'));

const Admin = lazy(() => import('./app/Admin'));

const BuilderTest = lazy(() => import('./app/BuilderQuestions'));

const Mail = lazy(() => import('./app/Mail'));

const Learning = lazy(() => import('./app/Learning'));

const Dashboard = lazy(() => import('./app/Dashboard'));

const MiddlewareLogin = lazy(() => import('./app/Middleware/MiddlewareLogin'));

const MiddlewareSignUp = lazy(() => import('./app/Middleware/MiddlewareSignUp'));

const MiddlewareForgot = lazy(() => import('./app/Middleware/MiddlewareForgot'));

const MiddlewareForgotSuccess = lazy(() => import('./app/Middleware/MiddlewareForgotSuccess'));

const MiddlewareResetPassword = lazy(() => import('./app/Middleware/MiddlewareResetPassword'));

const Modal = lazy(() => import('./app/Modal'));

const ModalRoute = lazy(() => import('./app/Modal/ModalRoute'));

import Middleware from './app/Middleware';
import { EnvironmentProvider } from './shared/environment';
const Alert = lazy(() => import('./app/Alert'));

const Conference = lazy(() => import('./app/Conference'));

modesActivate();

const App = () => (
  <Provider store={store}>
    <ProviderINTL>
      <EnvironmentProvider>
        <Suspense fallback={<Preloader />}>
          <BrowserRouter>
            <Middleware exception={<Conference />}>
              <Routes>
                <Route path={getRoute('AP', 'path')} element={<AdminPanel />} />
                <Route path={getRoute('DP', 'path')} element={<Dashboard />} />
                <Route path={getRoute('BQ', 'path')} element={<BuilderTest />} />
                <Route path={getRoute('L', 'path')} element={<Learning />} />
                <Route path={getRoute('AHP', 'path')} element={<Admin />} />
                <Route path={getRoute('S', 'path')} element={<Site />} />
                <Route path={getRoute('AL', 'path')} element={<MiddlewareLogin />} />
                <Route path={getRoute('AS', 'path')} element={<MiddlewareSignUp />} />
                <Route path={getRoute('AF', 'path')} element={<MiddlewareForgot />} />
                <Route path={getRoute('AFS', 'path')} element={<MiddlewareForgotSuccess />} />
                <Route path={getRoute('ARP', 'path')} element={<MiddlewareResetPassword />} />
                <Route path={getRoute('M', 'path')} element={<Mail />} />
              </Routes>
              <ModalRoute />
            </Middleware>
            <Modal />
            <Alert />
          </BrowserRouter>
        </Suspense>
      </EnvironmentProvider>
    </ProviderINTL>
  </Provider>
);

const rootElement = $root;

if (rootElement) {
  const root = ReactDOM.createRoot(rootElement);

  root.render(<App />);
} else {
  console.error('Root element not found. Check the initialization of $root.');
}

serviceWorker.unregister();
