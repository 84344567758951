import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode, useEffect } from 'react';
import { NavLink, Route, Routes } from 'react-router'; // Updated Route handling for React Router v6
import { useRouter, useToState } from '../../hooks';
import Die from '../Die';
import Permission from '../Permission';
import Scroll from '../Scroll';
import Text from '../Text';

interface IProps {
  onChange?(name: string, params: object | null, data: any | null): void;
  data: Array<{
    title: string | ReactNode;
    data: ReactNode | string;
    params?: object;
    die?: string;
    name?: string;
    path?: string;
    permissions?: Array<string>;
    allowedRoles?: Array<string | object>;
  }>;
  children?: ReactNode;
  type?: string;
  deprecated?: boolean;
  to?: any;
  className?: string;
}

interface IState {
  activeTabContent: any;
  data: Array<{
    title: string;
    data: ReactNode | string;
    params?: object;
    name: string;
  }>;
  activeTabPath: string;
  activeTabsIndex: number;
}

const Tabs: FC<IProps> = ({
  data,
  onChange,
  children,
  type,
  deprecated = false,
  to,
  className,
}: IProps): ReactElement => {
  const { match, history } = useRouter();

  const [state, setState] = useToState<IState>({
    activeTabContent: data[0]?.data ?? {},
    activeTabPath: match.url,
    data: [],
    activeTabsIndex: deprecated ? 0 : -1,
  });

  const openTab = (e, data) => {
    onChange && onChange(data?.name, data.params, data);
  };

  const onChangeDeprecatedMode = (e, data) => {
    setState({ activeTabsIndex: +e.target.dataset.index });

    onChange && onChange(data, null, null);
  };

  const defaultLoadContent = () => {
    setState({
      data,
    });
  };

  const toMoveLoad = () => {
    if (to && to.length) {
      const { path } = to[0];

      history.push(path);
    }
  }; //TODO [Thunk]

  useEffect(defaultLoadContent, [data]);

  useEffect(toMoveLoad, [to]);

  const generateRoute = (item) => {
    return item?.path ? item.path : `${match.url}${item?.name}`;
  };

  const generateLink = (item) => {
    return item?.linkPath ? item.linkPath : `${match.url}${item?.name}`;
  };

  const renderNavWithPermissions = (data, index) => {
    return (
      <Permission is={data.permissions} allowedRoles={data?.allowedRoles} key={index}>
        <NavLink
          data-testid={generateLink(data)}
          className="tabs__link"
          to={generateLink(data)}
          onClick={(e) => openTab(e, data)}
        >
          <Text>
            <strong>{data?.title}</strong>
          </Text>
        </NavLink>
      </Permission>
    );
  };

  const renderNav = (data, index) => {
    return (
      <NavLink
        data-testid={generateLink(data)}
        key={index}
        className="tabs__link"
        to={generateLink(data)}
        onClick={(e) => openTab(e, data)}
      >
        <Text>
          <strong>{data?.title}</strong>
        </Text>
      </NavLink>
    );
  };

  const renderTabsLinks = () => {
    const { data } = state;

    return data.map((item, i) => (item?.permissions ? renderNavWithPermissions(item, i) : renderNav(item, i)));
  };

  const renderRouterContent = () => {
    const { data } = state;

    return (
      <Routes>
        {data.map((item, i) => (
          <Route
            key={i}
            path={generateRoute(item)}
            element={<div data-testid={generateRoute(item)}>{item.data}</div>}
          />
        ))}
      </Routes>
    );
  };

  const renderRouteMode = () => {
    return (
      <>
        <div className="tabs__links">
          <Scroll>
            <div className="tabs__links-scroll">{renderTabsLinks()}</div>
          </Scroll>
        </div>
        {renderRouterContent()}
      </>
    );
  };

  const renderDeprecatedMode = () => {
    const { activeTabsIndex } = state;

    const isActiveContent = data[activeTabsIndex];

    return (
      <>
        <div className="tabs__links">
          <Scroll>
            <div className="tabs__links-scroll">
              {data.map((item, i) =>
                !item?.die ? (
                  <Text
                    dataTestid={item?.name}
                    key={i}
                    className={`tabs__link ${i === state.activeTabsIndex ? 'active' : ''}`}
                    onClick={(e) => onChangeDeprecatedMode(e, item)}
                    dataIndex={i}
                  >
                    {item?.title}
                  </Text>
                ) : (
                  <Die label={item?.die} key={`table-die-item-${i}`}>
                    <Text
                      dataTestid={item?.name}
                      key={i}
                      className={`tabs__link ${i === state.activeTabsIndex ? 'active' : ''}`}
                      onClick={(e) => onChangeDeprecatedMode(e, item)}
                      dataIndex={i}
                    >
                      {item?.title}
                    </Text>
                  </Die>
                )
              )}
            </div>
          </Scroll>
        </div>
        {isActiveContent && <div className={`tabs__content-mode`}>{isActiveContent.data}</div>}
      </>
    );
  };

  const renderModes = () => {
    return deprecated ? renderDeprecatedMode() : renderRouteMode();
  };

  const classesTabs = classNames('tabs', className, {
    [`tabs--${type}`]: type,
  });

  return (
    <div className={classesTabs}>
      {renderModes()}
      {children && <div className={`tabs__caption`}>{children}</div>}
    </div>
  );
};

export default Tabs;
