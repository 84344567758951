import './index.scss';
import React, { FC, ReactNode } from 'react';
import Icon from '../Icon';

interface IProps {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
}

interface IParts {
  children?: ReactNode;
  onClick?: () => void;
  className?: string;
}

const field = {
  app: 't--panel',
  head: 't--panel__head',
  foot: 't--panel__foot',
  back: 't--panel__back',
  tour: 't--panel__tour',
};

const LayoutPart: FC<IParts> = ({ children, className, onClick }) => (
  <div className={className} {...(onClick && { onClick })}>
    {children}
  </div>
);

export const Head: FC<IProps> = ({ children }) => <LayoutPart className={field.head}>{children}</LayoutPart>;

export const Back: FC<IProps> = ({ children, onClick }) => (
  <LayoutPart className={field.back} onClick={onClick}>
    <>
      <Icon name="Arrow" />
      {children}
    </>
  </LayoutPart>
);

export const Foot: FC<IProps> = ({ children }) => <LayoutPart className={field.foot}>{children}</LayoutPart>;

export const Tour: FC<IProps> = ({ children }) => <LayoutPart className={field.tour}>{children}</LayoutPart>;

const TPanel: FC<IProps> & {
  Head: typeof Head;
  Back: typeof Back;
  Foot: typeof Foot;
  Tour: typeof Tour;
} = ({ children }) => {
  return <div className={field.app}>{children}</div>;
};

// Добавляем дочерние компоненты как свойства TPanel
TPanel.Head = Head;
TPanel.Back = Back;
TPanel.Foot = Foot;
TPanel.Tour = Tour;

export default TPanel;
