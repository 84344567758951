import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface IProps {
  children: ReactNode;
  size?: string;
  onClick?(e: any): void;
  toggle?: boolean;
  className?: string;
  active?: boolean;
  invert?: boolean;
  revert?: boolean;
  outlined?: boolean;
  auto?: boolean;
  off?: boolean;
  hover?: boolean;
  title?: string;
  monochrome?: boolean;
  activeCheck?: boolean;
  innerRef?: any;
  variant?: string;
  hoverDark?: boolean;
  collapse?: boolean;
  play?: boolean;
  mode?: string;
  dataTestid?: string;
}

const Square: FC<IProps> = ({
  children,
  size = 'md',
  onClick,
  className,
  invert,
  revert,
  outlined,
  auto,
  off,
  hover,
  title,
  monochrome,
  innerRef,
  active,
  variant,
  hoverDark,
  collapse,
  mode,
  play,
  dataTestid,
}) => {
  const params = {
    title,
    ...(onClick && { onClick }),
    ...(innerRef && { ref: innerRef }),
  };

  const classesSquare = classNames(className, 'square--eject', `square--${size}`, {
    [`square--${mode}`]: mode,
    'square--collapse': collapse,
    'square--active': active,
    'square--monochrome': monochrome,
    'square--hover': hover,
    'square--hover-dark': hoverDark,
    'square--off': off,
    'square--auto': auto,
    'square--invert': invert,
    'square--revert': revert,
    'square--outlined': outlined,
    'square--play': play,
    [`square--${variant}`]: variant,
  });

  return (
    <div data-testid={dataTestid} className={classesSquare} {...params}>
      {children}
    </div>
  );
};

export default Square;
