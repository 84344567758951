import axios from 'axios';
import types from '../app/Middleware/middleware.types';
import { store } from '../store/configureStore';
import {
  IRequestConstructor,
  IRequestDataParse,
  IRequestDataParsed,
  IRequestProvider,
  IRequestQueryParams,
} from './index';

class Provider implements IRequestProvider {
  host: string;

  token?: string;

  private availableUrls: Array<string>;

  constructor({ host }: IRequestConstructor) {
    this.host = host;
    this.token = '';
    this.availableUrls = ['localhost', '130.193.48.119', 'edbee'];
  }

  static createQueryParams(object: IRequestQueryParams | {}): string {
    let params = '?';

    for (const key in object) {
      if (object.hasOwnProperty(key)) {
        const field = object[key];

        params += field && field !== '' ? `${key}=${field}&` : '';
      }
    }
    return params.substring(-1, params.length - 1);
  }

  private async request(path = '', params = Object.create(null), type?: string): Promise<any> {
    const filter = this.availableUrls.filter((url) => {
      const isUrl = this.host + path;

      return isUrl.match(url);
    });

    const requestTypes = {
      default: {
        ...(params && {
          ...params,
          data: JSON.stringify(params.data),
        }),
        ...(filter.length && {
          credentials: 'include',
        }),
        withCredentials: true,
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          ...params.headers,
        },
      },
      file: {
        data: params,
        credentials: 'include',
        withCredentials: true,
      },
    };

    return new Promise((resolve, reject) => {
      axios({ url: this.host + path, ...requestTypes[type ?? 'default'] })
        .then((response: any) => {
          if (response.status < 200 || response.status > 300) {
            const grabError = response.data;

            // eslint-disable-next-line
            throw {
              ...new Error(),
              ...grabError,
            };
          }

          resolve(response.data);
        })
        .catch((error) => {
          const status = error?.response?.status;

          const data = error?.response?.data;

          reject(data);

          const typesStatus = {
            401: () => {
              localStorage.setItem('session', 'false');
              store.dispatch({
                type: types.SESSION,
                session: null,
              });
            }, //TODO deprecated
            404: () => {
              store.dispatch({
                type: types.SESSION,
                session: {
                  ...store.getState().middleware.session,
                  connect: '404',
                },
              }); //TODO deprecated
            },
          };

          typesStatus[status] && typesStatus[status]();

          // if (status === 401) {
          //   store.dispatch({
          //     type: types.SESSION,
          //     session: null,
          //   }); //TODO deprecated
          // }
        });
    });
  }

  private requestUploadFile(path, formData, progress, method: string = 'post', options) {
    const config = {
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);

        progress && progress(percentCompleted);
      },
      withCredentials: true,
      ...(options && {
        cancelToken: options.cancelToken.token,
      }),
    };

    return new Promise((resolve, reject) => {
      axios[method](this.host + path, formData, config)
        .then((response: any) => {
          if (response.status < 200 || response.status > 300) {
            const grabError = response.data;

            // eslint-disable-next-line
            throw {
              ...new Error(),
              ...grabError,
            };
          }

          resolve(response.data);
        })
        .catch((error) => {
          reject(error);
          const status = error?.response?.status;

          if (status === 401) {
            store.dispatch({
              type: types.SESSION,
              session: null,
            }); //TODO deprecated
          }
        });
    });
  }

  private dataParse(data: IRequestDataParse): IRequestDataParsed {
    const { path, query, params, success, error, formData, headers } = data;

    return {
      path: path ? path : '',
      query: query ? Provider.createQueryParams(query) : '',
      params: { ...(params && params) },
      headers: { ...(headers && headers) },
      ...(formData && { params: formData }),
      success(response: Response): void {
        success && success(response);
      },
      error(response: Error): void {
        error && error(response);
      },
    };
  }

  private queryParser(pathname: string | Function, data: IRequestDataParse, method: string): void {
    const { path, query, success, error, headers } = this.dataParse(data);

    // const formationPathName = typeof pathname === 'function' ? pathname(path) : pathname + path;
    const formationPathName =
      typeof pathname === 'function' ? (Array.isArray(path) ? pathname(...path) : pathname(path)) : pathname + path;

    this.request(formationPathName + query, {
      ...(headers && { headers }),
      method: method,
    })
      .then((response: Response) => {
        success(response);
      })
      .catch((response: Error) => {
        error(response);
      });
  }

  private bodyParser(pathname: string | Function, data: IRequestDataParse, method: string, uploadFile?: string): void {
    const { path, params, success, error, headers } = this.dataParse(data);

    const formationPathName =
      typeof pathname === 'function' ? (Array.isArray(path) ? pathname(...path) : pathname(path)) : pathname + path;

    const options = {
      ...(headers && { headers }),
      data: params,
      method: method,
    };

    this.request(formationPathName, options, uploadFile)
      .then((response: Response) => {
        success(response);
      })
      .catch((response: Error) => {
        error(response);
      });
  }

  public init(name) {
    const errorProcessing = (dispatch, errorMessage) => {
      return errorMessage;
      // dispatch({
      //   type: 'SET_ERROR_MESSAGE',
      //   errorMessage,
      // });//TODO [Deprecated]: Rework on formation on methods of code
    };

    const action: Function = (data) => (dispatch) => {
      return new Promise((resolve, reject) => {
        this[name]({
          ...(data && data),
          success(response: Response) {
            resolve(response);
          },
          error(error: Error) {
            reject(errorProcessing(dispatch, error));
          },
        });
      });
    };

    return (data) => action(data);
  }

  protected get(path: string | Function = '', data: IRequestDataParse): void {
    this.queryParser(path, data, 'GET');
  }

  protected post(path: string | Function = '', data: IRequestDataParse): void {
    this.bodyParser(path, data, 'POST');
  }

  protected put(path: string | Function = '', data: IRequestDataParse): void {
    this.bodyParser(path, data, 'PUT');
  }

  protected delete(path: string | Function = '', data: IRequestDataParse): void {
    this.bodyParser(path, data, 'DELETE');
  }

  protected patch(path: string | Function = '', data: IRequestDataParse): void {
    this.bodyParser(path, data, 'PATCH');
  }

  protected upload(path: string | Function = '', data): Promise<any> {
    const { params, progressCallback, method, query, options } = data;

    const formationPathName = typeof path === 'function' ? path(data?.path) : data?.path ? path + data?.path : path;

    return this.requestUploadFile(
      `${formationPathName}${Provider.createQueryParams(query)}`,
      params,
      progressCallback,
      method,
      options
    );
  } //TODO [Update]: Rework on parser

  protected through(path: string, data: IRequestDataParse): void {
    const { success, error } = this.dataParse(data);

    axios(path)
      .then((response) => success(response?.data))
      .catch(error);
  }
}

export default Provider;
