import React from 'react';

interface IProps {
  className?: string;
}

export default function ELPlusMini(props: IProps) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="12" height="13" fill="none" viewBox="0 0 12 13" {...props}>
      <path stroke="currentColor" strokeLinecap="round" strokeWidth="2" d="M6 1v10.244m5-5.122H1" />
    </svg>
  );
}
