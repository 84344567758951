import React, { FC, ReactElement, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { IReduxStore } from '../../../../store/serviceReducer';
import { uuid } from '../../../../utils';
import { setAlert } from '../../../app.actions';
import { setTemplate } from '../../builder.questions.actions';
import {
  IBuilderTestsGetQuestion,
  IBuilderTestsQuestionTo,
  IBuilderTestsTemplateQuestion,
  IBuilderTestsTemplates,
} from '../../builder.questions.resources';
import { questionUpdate } from '../../builder.questions.services';
import Bridge from '../questions.create.pipes';

interface IProps {
  children: any;
  data: IBuilderTestsTemplateQuestion;
  template: IBuilderTestsTemplates;
  setTemplateAction(data: IBuilderTestsTemplates): void;
  questionUpdateAction(path: number, data: IBuilderTestsQuestionTo): Promise<IBuilderTestsGetQuestion>;
  setAlertAction: any;
}

const QuestionService: FC<IProps> = ({
  data,
  template,
  setTemplateAction,
  questionUpdateAction,
  children,
  setAlertAction,
}): ReactElement => {
  const [localFieldName, setLocalFieldName] = useState<string>(data.body ?? '');

  const findBlock = () => template && ([[...template], [...template[data.indexUp].data]] as any);

  const onChangeFieldName = (value: string, key = 'body') => {
    setLocalFieldName(value);

    if (!template) return;

    const newTemplate = template.map((block, blockIndex) => {
      if (blockIndex !== data.indexUp) return block;
      return {
        ...block,
        data: block.data.map((question, questionIndex) => {
          if (questionIndex !== data.indexDown) return question;
          const updatedQuestion = {
            ...question,
            [key]: value,
          };

          return {
            ...updatedQuestion,
            ...Bridge.onErrorChange(updatedQuestion),
          };
        }),
      };
    });

    setTemplateAction(newTemplate as IBuilderTestsTemplates);
  };

  const onChangeFieldAudio = (value) => {
    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const newCloneBlock = {
      ...cloneBlock,
      ...value,
    };

    questions[data.indexDown] = {
      ...newCloneBlock,
      ...Bridge.onErrorChange(newCloneBlock),
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);
  };

  const onChangeFieldUnder = (e, key, type) => {
    const { checked, value } = e.target;

    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const newCloneBlock = {
      ...cloneBlock,
      typeFields: {
        ...cloneBlock.typeFields,
        [key]: type !== 'radio' ? checked : !!+value,
      },
    };

    questions[data.indexDown] = {
      ...newCloneBlock,
      ...Bridge.onErrorChange(newCloneBlock),
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);
  };

  const onTestOnError = () => {
    const [part, questions] = findBlock();

    const cloneBlock = questions[data.indexDown];

    const isError = Bridge.onValidData(cloneBlock);

    questions[data.indexDown] = {
      ...cloneBlock,
      ...isError,
    };

    part[data.indexUp].data = questions;

    setTemplateAction(part);

    return isError;
  };

  const onSaveQuestion = () => {
    const requestData = Bridge.onRequestData(data);

    requestData.position = data.indexDown;

    const error = onTestOnError() as any;

    const isValidFields = 'error' in error && !error?.error;

    return (
      isValidFields &&
      new Promise((resolve) => {
        questionUpdateAction(data.id, requestData).then((response) => {
          resolve(response);
          setAlertAction({
            code: '080202',
            uuid: uuid(),
            params: {},
          });
        });
      })
    );
  };

  return children({
    data: { ...data, body: localFieldName },
    onChangeFieldName,
    onChangeFieldAudio,
    onChangeFieldUnder,
    onSaveQuestion,
    onTestOnError,
  });
};

const mapStateToProps = (store: IReduxStore) => ({
  template: store.builderTests.template!,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
  setTemplateAction: bindActionCreators(setTemplate, dispatch),
  questionUpdateAction: bindActionCreators(questionUpdate, dispatch),
  setAlertAction: bindActionCreators(setAlert, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(QuestionService);
