import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactNode } from 'react';

interface IProps {
  iconName?: string;
  label?: ReactNode;
  error?: ReactNode;
  errorText?: ReactNode;
  className?: string;
  variant?: string;
  bold?: boolean;
  children?: ReactNode;
  onClick?: Function;
  decoration?: string;
  hoverMode?: string;
  dataIndex?: number;
  title?: string;
  disabled?: boolean;
  dataTestid?: string;
}

const Text: FC<IProps> = function ({
  iconName,
  className = '',
  variant = 'lg',
  error,
  errorText,
  children,
  label,
  onClick,
  bold,
  decoration,
  hoverMode,
  dataIndex,
  title,
  disabled,
  dataTestid,
}): ReactElement {
  const onChange = (e) => {
    onClick && onClick(e);
  };

  const classesText = classNames(className, 'text--eject', {
    [`text--hm-${hoverMode}`]: hoverMode,
    [`text--${variant}`]: variant,
    [`text--${decoration}`]: decoration,
    'text--b': bold,
    'text--disabled': disabled,
    error: error,
  });

  const params = {
    'data-index': dataIndex,
    title,
  };

  return (
    <div data-testid={dataTestid} className={classesText} onClick={onChange} {...params}>
      {iconName && <span className={iconName} />}
      {children}
      {label && <label>{label}</label>}
      {error && errorText && <span>{errorText ? errorText : error}</span>}
    </div>
  );
};

export default Text;
