import classNames from 'classnames';
import React, { FC } from 'react';
import logo from '../../assets/images/logo-pochet.svg';
interface IProps {
  active?: boolean;
  className?: string;
}

const LogoPochet: FC<IProps> = () => {
  return (
    <div>
      <img src={logo} alt="" />
    </div>
  );
};

export default LogoPochet;
