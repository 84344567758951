import './index.scss';
import React, { FC, ReactElement } from 'react';
import { useRouter } from '../../../../hooks';
import translate from '../../../../i18n/translate';
import { getRoute } from '../../../../routes/client';
import Button from '../../../Button';
import Text from '../../../Text';

interface IProps {
  onConfirm: any;
  text: string;
  onClose: any;
}

const Tariff: FC<IProps> = function ({ onConfirm, onClose }): ReactElement {
  const { history } = useRouter();

  const onToExpand = () => {
    onConfirm && onConfirm();
    history.push(getRoute('AP/EX', 'path', { full: true }));
  };

  return (
    <div className="delete-test">
      <Text variant="lg">
        <strong>Сменить тариф</strong>
      </Text>
      <Button size="sm" variant="outlined" bold onClick={onClose}>
        {translate('g.btn.cancel')}
      </Button>
      <Button size="sm" onClick={onToExpand}>
        Сменить
      </Button>
    </div>
  );
};

export default Tariff;
