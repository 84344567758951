import Add from './storage/Add';
import AddLight from './storage/AddLight';
import AddPrimary from './storage/AddPrimary';
import Arrow from './storage/Arrow';
import ArrowDown from './storage/ArrowDown';
import ArrowDownBold from './storage/ArrowDownBold';
import ArrowSlideDown from './storage/ArrowSlideDown';
import ArrowSmall from './storage/ArrowSmall';
import ArrowStruct from './storage/ArrowStruct';
import ArrowSwipe from './storage/ArrowSwipe';
import ArrowUp from './storage/ArrowUp';
import ArrowUse from './storage/ArrowUse';
import ArrowXS from './storage/ArrowXS';
import BellActive from './storage/BellActive';
import Bicycle from './storage/Bicycle';
import Chat from './storage/Chat';
import ChatEmpty from './storage/ChatEmpty';
import Check from './storage/Check';
import CheckJack from './storage/CheckJack';
import CheckPart from './storage/CheckPart';
import CheckSuccess from './storage/CheckSuccess';
import City from './storage/City';
import Clip from './storage/Clip';
import Clock from './storage/Clock';
import CloseBold from './storage/CloseBold';
import CloseMobile from './storage/CloseMobile';
import CoverEvent from './storage/CoverEvent';
import CoverEventNew from './storage/CoverEventNew';
import CoverEventSmall from './storage/CoverEventSmall';
import Cup from './storage/Cup';
import Delete from './storage/Delete';
import Dots from './storage/Dots';
import DoubleArrow from './storage/DoubleArrow';
import Dust from './storage/Dust';
import ELAddDir from './storage/ELAddDir';
import ELAddLightCircle from './storage/ELAddLightCircle';
import ELAddLightCirclePochet from './storage/ELAddLightCirclePochet';
import ELAgency from './storage/ELAgency';
import ELAlphabet from './storage/ELAlphabet';
import ELArrowLineBottom from './storage/ELArrowLineBottom';
import ELArrowLineLeft from './storage/ELArrowLineLeft';
import ELArrowLineRight from './storage/ELArrowLineRight';
import ELArrowLineTop from './storage/ELArrowLineTop';
import ELAttempt from './storage/ELAttempt';
import ELAttemptBest from './storage/ELAttemptBest';
import ELAttemptWorst from './storage/ELAttemptWorst';
import ELAudio from './storage/ELAudio';
import ELBell from './storage/ELBell';
import ELBook from './storage/ELBook';
import ELBurger from './storage/ELBurger';
import ELCalendar from './storage/ELCalendar';
import ELCalendarNot from './storage/ELCalendarNot';
import ELCells from './storage/ELCells';
import ELCheck from './storage/ELCheck';
import ELCheckCircle from './storage/ELCheckCircle';
import ELClose from './storage/ELClose';
import ELCloseCircle from './storage/ELCloseCircle';
import ELCloseModal from './storage/ELCloseModal';
import ELCopy from './storage/ELCopy';
import ELDefender from './storage/ELDefender';
import ELDownload from './storage/ELDownload';
import ELEvents from './storage/ELEvents';
import ELExpandLg from './storage/ELExpandLg';
import ELExpandMd from './storage/ELExpandMd';
import ELEye from './storage/ELEye';
import ELEyeHide from './storage/ELEyeHide';
import ELFile from './storage/ELFile';
import ELFilter from './storage/ELFilter';
import ELFolder from './storage/ELFolder';
import ELFullScreen from './storage/ELFullScreen';
import ELFullScreenOut from './storage/ELFullScreenOut';
import ELGrid from './storage/ELGrid';
import ELGroups from './storage/ELGroups';
import ELHint from './storage/ELHint';
import ELHomework from './storage/ELHomework';
import ELHourglass from './storage/ELHourglass';
import ELImage from './storage/ELImage';
import ELLearn from './storage/ELLearn';
import ELLearning from './storage/ELLearning';
import Ellipsis from './storage/Ellipsis';
import ELLogout from './storage/ELLogout';
import ELMark from './storage/ELMark';
import ELMarkAttempt from './storage/ELMarkAttempt';
import ELMiniRows from './storage/ELMiniRows';
import ELMinus from './storage/ELMinus';
import ELPage from './storage/ELPage';
import ELPause from './storage/ELPause';
import ELPayments from './storage/ELPayments';
import ELPencil from './storage/ELPencil';
import ELPlay from './storage/ELPlay';
import ELPlus from './storage/ELPlus';
import ELPlusFill from './storage/ELPlusFill';
import ELPlusMini from './storage/ELPlusMini';
import ELQuestionAttempt from './storage/ELQuestionAttempt';
import ELQuestionFile from './storage/ELQuestionFile';
import ELQuestionSmall from './storage/ELQuestionSmall';
import ELRocket from './storage/ELRocket';
import ELRocketMin from './storage/ELRocketMin';
import ELRows from './storage/ELRows';
import ELSearch from './storage/ELSearch';
import ELSettings from './storage/ELSettings';
import ELStatsAccept from './storage/ELStatsAccept';
import ELStatsNotYet from './storage/ELStatsNotYet';
import ELStatsReject from './storage/ELStatsReject';
import ElStorageSearch from './storage/ElStorageSearch';
import ELTariff from './storage/ELTariff';
import ELTelegram from './storage/ELTelegram';
import ELTestPointsReward from './storage/ELTestPointsReward';
import ELTests from './storage/ELTests';
import ELTestsLibrary from './storage/ELTestsLibrary';
import ELTimerAttempt from './storage/ELTimerAttempt';
import ELTimerSmall from './storage/ELTimerSmall';
import ELUpload from './storage/ELUpload';
import ELUser from './storage/ELUser';
import ELUserExpand from './storage/ELUserExpand';
import ELUsers from './storage/ELUsers';
import ELVideo from './storage/ELVideo';
import ELWarnCircle from './storage/ELWarnCircle';
import ELWarning from './storage/ELWarning';
import ELWebinar from './storage/ELWebinar';
import ELWebinarFile from './storage/ELWebinarFile';
import EW from './storage/EW';
import Flag from './storage/Flag';
import Handle from './storage/Handle';
import Infinity from './storage/Infinity';
import Key from './storage/Key';
import Lock from './storage/Lock';
import Mail from './storage/Mail';
import Permissions from './storage/Permissions';
import Photo from './storage/Photo';
import Question from './storage/Question';
import QuestionRevert from './storage/QuestionRevert';
import Required from './storage/Required';
import ScoreMobile from './storage/ScoreMobile';
import Scores from './storage/Scores';
import Scores2 from './storage/Scores2';
import StarQuestion from './storage/StarQuestion';
import StarSmall from './storage/StarSmall';
import StorageArrow from './storage/StorageArrow';
import StorageSearchButton from './storage/StorageSearchButton';
import StorageToGrid from './storage/StorageToGrid';
import StorageToRow from './storage/StorageToRow';
import Sync from './storage/Sync';
import Tags from './storage/Tags';
import TestPoints from './storage/TestPoints';
import TimeOld from './storage/TimeOld';
import Timer from './storage/Timer';
import Trash from './storage/Trash';
import Trash2 from './storage/Trash2';
import TypeChoice from './storage/TypeChoice';
import TypeFree from './storage/TypeFree';
import Upload from './storage/Upload';

export default {
  ELDefender,
  ELTariff,
  ELRocketMin,
  ELQuestionAttempt,
  ELMarkAttempt,
  ELBurger,
  ELMark,
  ELRocket,
  ELTelegram,
  ELDownload,
  ELCalendarNot,
  ELMiniRows,
  ELRows,
  ELCells,
  ELCheck,
  ELAttempt,
  ELTimerAttempt,
  ELPause,
  ELPlay,
  ELCloseModal,
  ELHourglass,
  ELLearn,
  ELLearning,
  ELGrid,
  ELAddDir,
  ELArrowLineTop,
  ELArrowLineRight,
  ELArrowLineBottom,
  ELArrowLineLeft,
  ELExpandLg,
  ELPlusFill,
  ELPlusMini,
  ELUpload,
  ELUser,
  ELMinus,
  ELPlus,
  ELExpandMd,
  Scores2,
  ELAgency,
  ArrowSlideDown,
  Required,
  ArrowDownBold,
  CloseMobile,
  ScoreMobile,
  TimeOld,
  Key,
  BellActive,
  ELCheckCircle,
  Mail,
  Lock,
  Permissions,
  Trash2,
  ELUsers,
  StarSmall,
  Tags,
  ELTimerSmall,
  ELQuestionSmall,
  StarQuestion,
  Add,
  ChatEmpty,
  CheckSuccess,
  Chat,
  TypeChoice,
  TypeFree,
  Upload,
  QuestionRevert,
  EW,
  AddLight,
  AddPrimary,
  ELQuestionFile,
  CheckPart,
  ELVideo,
  ELTests,
  ELPage,
  ELImage,
  ELHomework,
  ELBook,
  ELAudio,
  Cup,
  Handle,
  ELPencil,
  Dots,
  ELCopy,
  ELEye,
  Ellipsis,
  ELEyeHide,
  Trash,
  City,
  ArrowXS,
  ELAddLightCircle,
  ELAddLightCirclePochet,
  ArrowSmall,
  Infinity,
  CoverEventSmall,
  Scores,
  ELWarnCircle,
  Clock,
  ELCloseCircle,
  CloseBold,
  Clip,
  Photo,
  Delete,
  ArrowUp,
  ArrowStruct,
  Arrow,
  ELCalendar,
  ELFullScreenOut,
  ELFullScreen,
  Question,
  ELTestPointsReward,
  Timer,
  ELWebinarFile,
  ArrowDown,
  ArrowSwipe,
  ArrowUse,
  ELFile,
  StorageArrow,
  ELBell,
  CheckJack,
  ELFolder,
  ELClose,
  ELLogout,
  ELHint,
  ELSearch,
  ELSettings,
  ElStorageSearch,
  StorageSearchButton,
  ELGroups,
  StorageToGrid,
  StorageToRow,
  ELEvents,
  Flag,
  CoverEvent,
  CoverEventNew,
  TestPoints,
  Check,
  DoubleArrow,
  Sync,
  Bicycle,
  Dust,
  ELWarning,
  ELUserExpand,
  ELTestsLibrary,
  ELStatsNotYet,
  ELStatsAccept,
  ELStatsReject,
  ELAlphabet,
  ELFilter,
  ELAttemptWorst,
  ELAttemptBest,
  ELPayments,
  ELWebinar,
};
