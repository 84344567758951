import { useContext } from 'react';
import { EnvironmentContext, EnvironmentContextType } from './EnvironmentContext';

export const useEnvironment = (): EnvironmentContextType => {
  const environment = useContext(EnvironmentContext);

  if (!environment) {
    throw new Error('useEnvironment must be used within a EnvironmentProvider');
  }

  return environment!;
};
