import React, { FC, ReactElement, ReactNode } from 'react';
import { Link } from 'react-router';
import { getRoute } from '../../routes/client';
import Layout from '../Layout';
import Logo from '../Logo';
import Nav from '../Nav';
import Scroll from '../Scroll';

interface IProps {
  type?: string;
  children?: any;
  bottomData?: any;
  homeLink?: string | Function;
  dataNav: Array<{
    title: string;
    path: string;
    icon?: ReactNode;
    permissions?: Array<string> | null;
    target?: boolean;
  }>;
}

const AsideControl: FC<IProps> = function ({
  dataNav,
  children,
  type,
  bottomData,
  homeLink = getRoute('AP/E', 'path', { full: true }),
}): ReactElement {
  return (
    <>
      <Layout.AsideHead>
        <Link data-testid={`aside-${homeLink}`} to={homeLink}>
          <Logo />
        </Link>
      </Layout.AsideHead>
      <Layout.AsideBody>
        {children && children}
        <Scroll>
          <Layout.AsideInner>
            <Nav type={type} data={dataNav} />
            <Nav type="support" data={bottomData} />
          </Layout.AsideInner>
        </Scroll>
      </Layout.AsideBody>
    </>
  );
};

export default AsideControl;
