import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode } from 'react';

interface IProps {
  children?: ReactNode;
  className?: string;
}

interface IFields {
  [key: string]: string;
}

interface IParts {
  children?: ReactNode;
  className?: string;
}

const field: IFields = {
  app: 't--source',
  head: 't--source__head',
  foot: 't--source__foot',
  body: 't--source__body',
  content: 't--source__body-content',
  grid: 't--source__body-grid',
  cell: 't--source__body-cell',
  view: 't--source__body-view',
  block: 't--source__block',
};

const LayoutPart: FC<IParts> = ({ children, className }) => <div className={className}>{children}</div>;

export const Head: FC<IProps> = ({ children }) => <LayoutPart className={field.head}>{children}</LayoutPart>;

export const Body: FC<IProps> = ({ children }) => <LayoutPart className={field.body}>{children}</LayoutPart>;

export const Foot: FC<IProps> = ({ children }) => <LayoutPart className={field.foot}>{children}</LayoutPart>;

export const Content: FC<IProps> = ({ children }) => <LayoutPart className={field.content}>{children}</LayoutPart>;

export const Grid: FC<IProps> = ({ children }) => <LayoutPart className={field.grid}>{children}</LayoutPart>;

export const Cell: FC<IProps> = ({ children }) => <LayoutPart className={field.cell}>{children}</LayoutPart>;

export const View: FC<IProps> = ({ children }) => <LayoutPart className={field.view}>{children}</LayoutPart>;

export const Block: FC<IProps> = ({ children }) => <LayoutPart className={field.block}>{children}</LayoutPart>;

const TSource: FC<IProps> & {
  Head: typeof Head;
  Body: typeof Body;
  Foot: typeof Foot;
  Content: typeof Content;
  Grid: typeof Grid;
  Cell: typeof Cell;
  View: typeof View;
  Block: typeof Block;
} = ({ children, className }) => {
  const classes = classNames(field.app, className);

  return <div className={classes}>{children}</div>;
};

// Добавляем статические свойства для функционального компонента
TSource.Head = Head;
TSource.Body = Body;
TSource.Foot = Foot;
TSource.Content = Content;
TSource.Grid = Grid;
TSource.Cell = Cell;
TSource.View = View;
TSource.Block = Block;

export default TSource;
