import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactNode, useCallback, useRef } from 'react';
import { createReactEditorJS } from 'react-editor-js';
// import EditorJs from 'react-editor-js';
// // import ReactEditorJS from 'react-editor-js';
import Text from '../Text';
import { EDITOR_JS_TOOLS } from './tools';

const ReactEditorJS = createReactEditorJS();

interface IProps {
  data: any;
  onChange?(data: any, newData: any): any;
  add?: Array<string>;
  label?: string | ReactNode;
  error?: boolean;
  errorText?: ReactNode | string;
  dynamicWidth?: boolean;
  plugins?: Array<string>;
  minHeight?: number;
  variant?: string;
  placeholder?: string;
  readOnly?: boolean;
}

export const EditorPageParse = ({ data, className }: any) => {
  const classesEditorPageParse = classNames('editor-page-parse', className);

  return (
    <Text className={classesEditorPageParse}>
      <ReactEditorJS defaultValue={data} tools={EDITOR_JS_TOOLS as any} readOnly minHeight={150} />
    </Text>
  );
};

const EditorPage: FC<IProps> = ({ data = '', onChange, label, placeholder, error, readOnly = false }) => {
  const classesEditorPage = classNames('editor-page', {
    'editor-page--error': error,
  });

  const dataParamsI18n = {
    i18n: {
      /**
       * @type {I18nDictionary}
       */
      messages: {
        /**
         * Other below: translation of different UI components of the editor.js core
         */
        ui: {
          blockTunes: {
            toggler: {
              'Click to tune': 'Нажмите, чтобы настроить',
              'or drag to move': 'или перетащите',
            },
          },
          inlineToolbar: {
            converter: {
              'Convert to': 'Конвертировать в',
            },
          },
          toolbar: {
            toolbox: {
              Add: 'Добавить',
            },
          },
        },

        /**
         * Section for translation Tool Names: both block and inline tools
         */
        toolNames: {
          Text: 'Параграф',
          Heading: 'Заголовок',
          List: 'Список',
          Warning: 'Примечание',
          Checklist: 'Чеклист',
          Quote: 'Цитата',
          Code: 'Код',
          Delimiter: 'Разделитель',
          'Raw HTML': 'HTML-фрагмент',
          Table: 'Таблица',
          Link: 'Ссылка',
          Marker: 'Маркер',
          Bold: 'Полужирный',
          Italic: 'Курсив',
          Image: 'Изображение',
          InlineCode: 'Моноширинный',
        },

        /**
         * Section for passing translations to the external tools classes
         */
        tools: {
          /**
           * Each subsection is the i18n dictionary that will be passed to the corresponded plugin
           * The name of a plugin should be equal the name you specify in the 'tool' section for that plugin
           */
          warning: {
            // <-- 'Warning' tool will accept this dictionary section
            Title: 'Название',
            Message: 'Сообщение',
          },

          /**
           * Link is the internal Inline Tool
           */
          link: {
            'Add a link': 'Вставьте ссылку',
          },
          /**
           * The "stub" is an internal block tool, used to fit blocks that does not have the corresponded plugin
           */
          stub: {
            'The block can not be displayed correctly.': 'Блок не может быть отображен',
          },
        },

        /**
         * Section allows to translate Block Tunes
         */
        blockTunes: {
          /**
           * Each subsection is the i18n dictionary that will be passed to the corresponded Block Tune plugin
           * The name of a plugin should be equal the name you specify in the 'tunes' section for that plugin
           *
           * Also, there are few internal block tunes: "delete", "moveUp" and "moveDown"
           */
          delete: {
            Delete: 'Удалить',
          },
          moveUp: {
            'Move up': 'Переместить вверх',
          },
          moveDown: {
            'Move down': 'Переместить вниз',
          },
        },
      },
    },
  };

  const editorJS = useRef(null) as any;

  const handleInitialize = useCallback((instance) => {
    editorJS.current = instance;
  }, []);

  const handleSave = () => {
    editorJS.current?.save().then((savedData) => {
      onChange && onChange(savedData, savedData);
    });
  };

  return (
    <div className={classesEditorPage}>
      <Text className="editor-page__polygon">
        <ReactEditorJS
          onInitialize={handleInitialize}
          defaultValue={data}
          tools={EDITOR_JS_TOOLS as any}
          readOnly={readOnly}
          onChange={handleSave}
          placeholder={placeholder}
          minHeight={150}
          i18n={dataParamsI18n.i18n}
        />
      </Text>
      {label && <Text className="editor-page__label">{label}</Text>}
    </div>
  );
};

export default EditorPage;
