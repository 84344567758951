const getProductionTypes = () => {
  const { REACT_APP_BUILD } = process.env as any;

  const types = {
    TEST: process.env.REACT_APP_API_URL_SERVER_TEST,
    PROD: process.env.REACT_APP_API_URL_SERVER_PROD,
  };

  return types[REACT_APP_BUILD?.trim() ?? 'TEST'];
};

const getApiUrl = (type: null | string = null) => {
  const { REACT_APP_API_URL_DEV } = process.env;

  const types = {
    production: getProductionTypes(),
    development: REACT_APP_API_URL_DEV,
  };

  return types[type ?? process.env.NODE_ENV];
};

const getProductionTypes__Landing = () => {
  const { REACT_APP_BUILD } = process.env as any;

  const types = {
    TEST: process.env.REACT_APP_API_URL_LANDING_TEST,
    PROD: process.env.REACT_APP_API_URL_LANDING_PROD,
  };

  return types[REACT_APP_BUILD?.trim() ?? 'TEST'];
};

const getApiUrl__Landing = () => {
  const { REACT_APP_API_URL_LANDING_TEST } = process.env;

  const types = {
    production: getProductionTypes__Landing(),
    development: REACT_APP_API_URL_LANDING_TEST,
  };

  return types[process.env.NODE_ENV];
};

export const CONFIG = {
  REACT_APP_API_URL: getApiUrl(),
  REACT_APP_URI_URL: getApiUrl('production'),
  REACT_APP_SOCKET_URL: process.env.REACT_APP_SOCKET_URL,
  NODE_ENV: process.env.NODE_ENV,
  REACT_APP_SENTRY_DNS: process.env.REACT_APP_SENTRY_DNS,
  REACT_APP_SENTRY_ACTIVATE: process.env.REACT_APP_BUILD === 'PROD',
  REACT_APP_API_URL_LANDING_PROD: process.env.REACT_APP_API_URL_LANDING_PROD,
  REACT_APP_API_URL_LANDING_TEST: process.env.REACT_APP_API_URL_LANDING_TEST,
  REACT_APP_API_URL_LANDING: getApiUrl__Landing(),
  REACT_APP_API_DOMAIN_EXCEPTION: process.env.REACT_APP_API_DOMAIN_EXCEPTION,
  REACT_APP_API_DOMAIN_MEET: process.env.REACT_APP_API_DOMAIN_MEET,
  REACT_APP_TINYMCE_API_KEY: process.env.REACT_APP_TINYMCE_API_KEY,
};
