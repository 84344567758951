import translate from '../i18n/translate';

interface IRouters {
  [key: string]: string;
} //TODO [Deprecated]: Will be remove

export const TYPES = {
  ADMIN: 'ADMIN',
  USER: 'USER',
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n

export const PAGE: IRouters = {
  HOME: '/',
  ADMIN_PANEL: '/panel/*',
  ADMIN_HOST_PANEL: '/admin/*',
  DASHBOARD: '/-/*',
  BUILDER_PAGE: '/build/page',
  BUILDER_TEST: '/build/test',
  BUILDER_QUESTIONS: '/build/test/-/*',
  VIEW_TEST: '/test',
  LEARNING: '/in/*',
  DEV: '/dev',
  ERROR: '/404',
  ANY: '*',
  ACCEPTS_INVITES: '/accept-invite',
  ACCEPTS_VERIFY: '/verify',
}; //TODO [Deprecated]: Will be remove

export const APP = {
  ID: 'ROOT',
  PATHNAME: '/',
  CHILDREN: {
    AUTH_LOGIN: {
      ID: 'AL',
      PATHNAME: '/login',
    },
    AUTH_SIGNUP: {
      ID: 'AS',
      PATHNAME: '/signup',
    },
    AUTH_FORGOT: {
      ID: 'AF',
      PATHNAME: '/forgot',
    },
    AUTH_FORGOT_SUCCESS: {
      ID: 'AFS',
      PATHNAME: '/forgot/send',
    },
    AUTH_RESET_PASSWORD: {
      ID: 'ARP',
      PATHNAME: '/reset-password',
    },
    ADMIN_PANEL: {
      ID: 'ROOT',
      PATHNAME: '/',
      CHILDREN: {
        AUTH_LOGIN: {
          ID: 'AL',
          PATHNAME: '/login',
        },
        AUTH_SIGNUP: {
          ID: 'AS',
          PATHNAME: '/signup',
        },
        AUTH_FORGOT: {
          ID: 'AF',
          PATHNAME: '/forgot',
        },
        AUTH_FORGOT_SUCCESS: {
          ID: 'AFS',
          PATHNAME: '/forgot/send',
        },
        AUTH_RESET_PASSWORD: {
          ID: 'ARP',
          PATHNAME: '/reset-password',
        },
        ADMIN_PANEL: {
          ID: 'AP',
          PATHNAME: '/panel/*',
          CHILDREN: {
            EVENTS: {
              ID: 'AP/E',
              PATHNAME: 'events/*',
              MODE: ':type/*',
              TITLE: translate('events.name'),
              CHILDREN: {
                EVENT: {
                  ID: 'AP/E/-/',
                  PATHNAME: (id) => `-/${id}/*`,
                  SIGNATURES: [':id'],
                  BACK: true,
                },
                CURRENT: {
                  ID: 'AP/E/C',
                  TITLE: translate('events.overview'),
                  PATHNAME: 'overview',
                },
                UPCOMING: {
                  ID: 'AP/E/U',
                  TITLE: translate('events.active'),
                  PATHNAME: 'active',
                },
                COMPLETED: {
                  ID: 'AP/E/CD',
                  TITLE: translate('events.completed'),
                  PATHNAME: 'stale',
                },
                ARCHIVE: {
                  ID: 'AP/E/A',
                  TITLE: translate('events.archive'),
                  PATHNAME: 'archive',
                },

                EVENTS_SETTINGS: {
                  ID: 'AP/E/-/S',
                  TITLE: translate('g.settings'),
                  PATHNAME: (id) => `-/${id}/settings`,
                  SIGNATURES: [':id'],
                },
                EVENTS_USERS: {
                  ID: 'AP/E/-/U',
                  TITLE: translate('g.mmb'),
                  PATHNAME: (id) => `-/${id}/users`,
                  SIGNATURES: [':id'],
                },
                EVENTS_SITE: {
                  ID: 'AP/E/-/SC',
                  TITLE: translate('g.site'),
                  PATHNAME: (id) => `-/${id}/site`,
                  SIGNATURES: [':id'],
                },
                EVENTS_STRUCTURE: {
                  ID: 'AP/E/-/ST',
                  TITLE: translate('g.content'),
                  PATHNAME: (id) => `-/${id}/struct`,
                  SIGNATURES: [':id'],
                },
                EVENTS_PARTICIPANT: {
                  ID: 'AP/E/-/P',
                  TITLE: translate('g.usr.s'),
                  PATHNAME: (id) => `-/${id}/members`,
                  SIGNATURES: [':id'],
                  CHILDREN: {
                    USER: {
                      ID: 'AP/E/-/P/U',
                      TITLE: translate('g.see'),
                      PATHNAME: (id, userId) => `-/${id}/members/${userId}`,
                      SIGNATURES: [':id', ':userId'],
                      BACK: true,
                      CHILDREN: {
                        HOMEWORK: {
                          ID: 'AP/E/-/P/U/H',
                          TITLE: `ДЗ`,
                          PATHNAME: (id, userId, elementId) => `-/${id}/members/${userId}/homework/${elementId}`,
                          SIGNATURES: [':id', ':userId', ':elementId'],
                        },
                        STATISTIC: {
                          ID: 'AP/E/-/P/U/S',
                          TITLE: translate('g.statistic'),
                          PATHNAME: (id, userId, elementId) => `-/${id}/members/${userId}/stats/${elementId}/*`,
                          SIGNATURES: [':id', ':userId', ':elementId'],
                          BACK: true,
                          CHILDREN: {
                            ATTEMPT: {
                              ID: 'AP/E/-/P/U/S/A',
                              TITLE: translate('g.attempt'),
                              PATHNAME: (id, userId, elementId, attemptId) =>
                                `-/${id}/members/${userId}/stats/${elementId}/${attemptId}`,
                              SIGNATURES: [':id', ':userId', ':elementId', ':attemptId'],
                            },
                            ATTEMPT_ROUTE: {
                              ID: 'AP/E/-/P/U/S/A/R',
                              TITLE: translate('g.attempt'),
                              PATHNAME: (attemptId) => `/${attemptId}`,
                              SIGNATURES: [':attemptId'],
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
            TESTS: {
              ID: 'AP/T',
              PATHNAME: 'tests/*',
              TITLE: translate('ts.library'),
              CHILDREN: {
                TEST: {
                  ID: 'AP/T/-/',
                  PATHNAME: (id) => `-/${id}/*`,
                  SIGNATURES: [':id'],
                  BACK: true,
                },
                SETTINGS: {
                  ID: 'AP/T/-/S',
                  TITLE: translate('ts.overview'),
                  PATHNAME: (id) => `-/${id}/settings`,
                  SIGNATURES: [':id'],
                },
                FORMATION: {
                  ID: 'AP/T/-/F',
                  TITLE: translate('ts.formation'),
                  PATHNAME: (id) => `-/${id}/formation`,
                  SIGNATURES: [':id'],
                },
                RESULTS: {
                  ID: 'AP/T/-/R',
                  TITLE: translate('ts.results'),
                  PATHNAME: (id) => `-/${id}/results`,
                  SIGNATURES: [':id'],
                },
              },
            },
            AGENCY: {
              ID: 'AP/A',
              PATHNAME: 'agency/*',
              MODE: ':type/*',
              TITLE: translate('g.mmb'),
              CHILDREN: {
                USERS: {
                  ID: 'AP/A/U',
                  TITLE: translate('agency.staff'),
                  PATHNAME: 'users',
                },
                INVITATIONS: {
                  ID: 'AP/A/I',
                  TITLE: translate('g.ivt.tlt'),
                  PATHNAME: 'invitations',
                },
                ROLES: {
                  ID: 'AP/A/R',
                  TITLE: translate('g.roles'),
                  PATHNAME: 'roles',
                },
                PROFILE: {
                  ID: 'AP/A/-/P',
                  PATHNAME: '-/profile',
                  TITLE: translate('agency.profile'),
                },
                SWITCH: {
                  ID: 'AP/A/-/S',
                  PATHNAME: '-/switch',
                  TITLE: translate('agency.switch'),
                },
              },
            },
            PROFILE: {
              ID: 'AP/P',
              PATHNAME: 'me/*',
              TITLE: translate('g.my.profile'),
            },
            AGENCY_PROFILE: {
              ID: 'AP/PA',
              PATHNAME: 'profile*',
              TITLE: translate('agency.profile'),
            },
            GROUPS: {
              ID: 'AP/G',
              PATHNAME: 'groups/*',
              TITLE: 'Группы',
              CHILDREN: {
                GROUP: {
                  ID: 'AP/G/-/',
                  PATHNAME: (id) => `-/${id}/*`,
                  SIGNATURES: [':id'],
                  BACK: true,
                },
                SETTINGS: {
                  ID: 'AP/G/-/S',
                  TITLE: 'Настройки',
                  PATHNAME: (id) => `-/${id}/settings`,
                  SIGNATURES: [':id'],
                },
                INVITATIONS: {
                  ID: 'AP/G/-/I',
                  TITLE: translate('g.ivt.tlt'),
                  PATHNAME: (id) => `-/${id}/invitations`,
                  SIGNATURES: [':id'],
                },
                PARTICIPANTS: {
                  ID: 'AP/G/-/P',
                  TITLE: 'Участники',
                  PATHNAME: (id) => `-/${id}/participants`,
                  SIGNATURES: [':id'],
                },
              },
            },
            EXPAND: {
              ID: 'AP/EX',
              PATHNAME: 'expand/*',
              MODE: ':type/*',
              TITLE: translate('expand.opportunities'),
              CHILDREN: {
                PLAN: {
                  ID: 'AP/EX/T',
                  TITLE: translate('expand.rate'),
                  PATHNAME: 'plan',
                },
                PLAN_HISTORY: {
                  ID: 'AP/EX/TH',
                  TITLE: translate('expand.plan.history'),
                  PATHNAME: 'history',
                },
              },
            },
            STORAGE: {
              ID: 'AP/S',
              PATHNAME: `storage`,
              TITLE: translate('storage.tlt'),
            },
          },
        },

        NOTIFICATIONS: {
          ID: 'AP/N',
          PATHNAME: `notifications`,
          TITLE: translate('notice.name'),
        },
      },
    },
    ADMIN_HOST_PANEL: {
      ID: 'AHP',
      PATHNAME: PAGE.ADMIN_HOST_PANEL,
      CHILDREN: {
        USERS: {
          ID: 'AHP/U',
          PATHNAME: `users`,
        },
        CREATE_USER: {
          ID: 'AHP/U/C',
          PATHNAME: `users/create`,
        },
        UPDATE_USER: {
          ID: 'AHP/U/U',
          PATHNAME: (userId) => `users/${userId}`,
        },
        AGENCY: {
          ID: 'AHP/A',
          PATHNAME: `agency`,
        },
        CREATE_AGENT: {
          ID: 'AHP/A/C',
          PATHNAME: `agency/create`,
        },
        UPDATE_AGENCY: {
          ID: 'AHP/A/U',
          PATHNAME: (agencyId) => `agency/${agencyId}`,
        },
        AGENCY_USERS: {
          ID: 'AHP/A/UL',
          PATHNAME: (agencyId) => `${agencyId}/users`,
          SIGNATURES: [':agencyId'],
        },
        AGENCY_REGISTER_REQUEST: {
          ID: 'AHP/A/RQ',
          PATHNAME: `register-request`,
        },
        POSTS: {
          ID: 'AHP/P',
          PATHNAME: `posts/*`,
          MODE: `posts/*`,
          CHILDREN: {
            NEWS: {
              ID: 'AHP/P/N',
              PATHNAME: `news`,
            },
            INSTRUCTION: {
              ID: 'AHP/P/I',
              PATHNAME: `instruction`,
            },
            POST: {
              ID: 'AHP/P/S',
              PATHNAME: (agencyId) => `settings/${agencyId}`,
            },
          },
        },
      },
    },
    DASHBOARD: {
      ID: 'DP',
      PATHNAME: PAGE.DASHBOARD,
      CHILDREN: {
        EVENTS: {
          ID: 'DP/E',
          PATHNAME: `events/*`,
          MODE: `events/*`,
          TITLE: translate('events.name'),
          CHILDREN: {
            CURRENT: {
              ID: 'DP/E/C',
              TITLE: translate('events.current'),
              PATHNAME: `current`,
            },
            UPCOMING: {
              ID: 'DP/E/U',
              TITLE: translate('events.upcoming'),
              PATHNAME: `upcoming`,
            },
            COMPLETED: {
              ID: 'DP/E/CD',
              TITLE: translate('events.completed'),
              PATHNAME: `completed`,
            },
            INVITATIONS: {
              ID: 'DP/E/I',
              TITLE: translate('g.ivt.tlt'),
              PATHNAME: `invitations`,
            },
            REQUESTS: {
              ID: 'DP/E/RS',
              TITLE: translate('events.request'),
              PATHNAME: `requests`,
            },
            PASSING: {
              ID: 'DP/E/-/',
              TITLE: translate('events.passing'),
              PATHNAME: (id) => `-/${id}/*`,
              SIGNATURES: [':id'],
              BACK: true,
              CHILDREN: {
                //проверить
                STATISTIC: {
                  ID: 'DP/E/-/-/ST',
                  TITLE: translate('g.statistic'),
                  PATHNAME: (id, elementId) => `-/${id}/${elementId}/stats/*`,
                  SIGNATURES: [':id', ':elementId'],
                },
                ATTEMPT: {
                  ID: 'DP/E/-/-/ST/A',
                  TITLE: translate('g.attempt'),
                  PATHNAME: (id, elementId, attemptId) => `-/${id}/${elementId}/stats/${attemptId}`,
                  SIGNATURES: [':id', ':elementId'],
                },
                HOMEWORK: {
                  ID: 'DP/E/-/-/H',
                  TITLE: translate('homework.tlt'),
                  PATHNAME: (id, elementId) => `-/${id}/${elementId}/homework`,
                  SIGNATURES: [':id', ':elementId'],
                },
                STREAM: {
                  ID: 'DP/E/-/-/STR',
                  TITLE: translate('g.broadcast'),
                  PATHNAME: (id, elementId) => `-/${id}/${elementId}/stream`,
                  SIGNATURES: [':id', ':elementId'],
                },
                WEBINAR: {
                  ID: 'DP/E/-/-/WEB',
                  TITLE: translate('g.webinar'),
                  PATHNAME: (id, elementId) => `-/${id}/${elementId}/webinar`,
                  SIGNATURES: [':id', ':elementId'],
                },
              },
            },
          },
        },
        // NOTIFICATIONS: `n`,
        PROFILE: {
          ID: 'DP/P',
          PATHNAME: `me`,
          TITLE: translate('g.my.profile'),
        },
        NOTIFICATIONS: {
          ID: 'DP/N',
          PATHNAME: `notifications`,
          TITLE: translate('notice.name'),
        },
        GROUPS: {
          ID: 'DP/G',
          PATHNAME: `groups/*`,
          MODE: `groups/*`,
          TITLE: 'Группы',
          CHILDREN: {
            OVERVIEW: {
              ID: 'DP/G/O',
              TITLE: `Мои группы`,
              PATHNAME: `overview`,
            },
            INVITATIONS: {
              ID: 'DP/G/I',
              TITLE: translate('g.ivt.tlt'),
              PATHNAME: `invitations`,
            },
            GROUP: {
              ID: 'DP/G/-/',
              TITLE: 'Группа',
              PATHNAME: (id) => `-/${id}`,
              SIGNATURES: [':id'],
              BACK: true,
            },
          },
        },
        PAYMENTS: {
          ID: 'DP/PMT',
          PATHNAME: `payments`,
          TITLE: translate('pay.name'),
        },
      },
    },
    LEARNING: {
      ID: 'L',
      PATHNAME: PAGE.LEARNING,
      CHILDREN: {
        PREVIEW: {
          ID: 'L/--/',
          PATHNAME: (eventId, elementId) => `-/${eventId}/${elementId}`,
          SIGNATURES: [':eventId', ':elementId'],
        },
        PASSING: {
          ID: 'L/--/--/',
          PATHNAME: (eventId, elementId, attemptId, questionId) =>
            `-/${eventId}/${elementId}/${attemptId}/${questionId}`,
          SIGNATURES: [':eventId', ':elementId', ':attemptId', ':questionId'],
        },
        RESULT: {
          ID: 'L/-/-/-/',
          PATHNAME: (eventId, attemptId, elementId) => `-/${eventId}/${attemptId}/${elementId}`,
          SIGNATURES: [':eventId', ':attemptId', ':elementId'],
        },
      },
    },
    BUILDER_QUESTIONS: {
      ID: 'BQ',
      PATHNAME: PAGE.BUILDER_QUESTIONS,
      CHILDREN: {
        CREATE: {
          ID: 'BQ/-/',
          PATHNAME: (testId) => `${testId}`,
          SIGNATURES: [':id'],
        },
      },
    },
    SITE: {
      ID: 'S',
      PATHNAME: '/site/*',
      CHILDREN: {
        CREATE: {
          ID: 'S/C',
          PATHNAME: 'course',
          SIGNATURES: [':id'],
        },
      },
    },
    CONFERENCE: {
      ID: 'C',
      PATHNAME: '/*',
      CHILDREN: {
        CREATE: {
          ID: 'C/R',
          PATHNAME: (roomName) => `${roomName}`,
          SIGNATURES: [':id'],
        },
      },
    },
    ACCEPT: {
      ID: 'M',
      PATHNAME: '/*',
      CHILDREN: {
        ACCEPTS_INVITES: {
          ID: 'MC/I',
          PATHNAME: (acceptId) => `${PAGE.ACCEPTS_INVITES}/${acceptId}`,
          SIGNATURES: [':id'],
        },
        ACCEPTS_VERIFY: {
          ID: 'MV/I',
          PATHNAME: (acceptId) => `${PAGE.ACCEPTS_VERIFY}/${acceptId}`,
          SIGNATURES: [':id'],
        },
      },
    },
  },
};

type RouteKey = 'path' | 'title' | 'mode' | '';

type RouteOptions = {
  full?: boolean;
  isWildcard?: boolean;
  lastSegmentOnly?: boolean;
};

type RouteTypes = Record<RouteKey, any>;

type MovingFunction = (object: Record<string, any>, parentPath?: string) => void;
export const getRoute = <T extends RouteKey>(
  routeId: string,
  key: T,
  options: RouteOptions = {
    full: false,
    isWildcard: true,
    lastSegmentOnly: false,
  }
): any => {
  let searchSave: Record<string, any> = {};

  let fullPath = '';

  const moving: MovingFunction = (object, parentPath = '') => {
    for (const key in object) {
      const item = object[key];

      const ID = item?.ID;

      const PATHNAME = item?.PATHNAME;

      const CHILDREN = item?.CHILDREN;

      const currentPath = typeof PATHNAME === 'function' ? parentPath : `${parentPath}/${PATHNAME}`.replace(/\*$/g, '');

      if (ID === routeId) {
        searchSave = item;
        fullPath = parentPath;
        return;
      }

      if (CHILDREN) {
        moving(CHILDREN, currentPath);
      }
    }
  };

  moving(APP.CHILDREN, APP.PATHNAME);

  const types: RouteTypes = {
    path: (...params: any[]) => {
      const resolvedPath =
        typeof searchSave.PATHNAME === 'function' ? searchSave.PATHNAME(...params) : searchSave.PATHNAME;

      let basePath = options.full ? `/${fullPath}${resolvedPath || ''}` : resolvedPath || '';

      basePath = basePath.replace(/\/+/g, '/');

      if (options.lastSegmentOnly) {
        const segments = basePath.split('/').filter(Boolean);

        basePath = segments.pop() || '';
      }

      return options.isWildcard ? basePath : basePath.replace(/\*$/, '');
    },
    title: searchSave?.TITLE || '',
    mode: searchSave?.MODE || '',
    '': searchSave,
  };

  if ((key === 'path' || key === '') && typeof searchSave.PATHNAME !== 'function') {
    let resolvedPath = searchSave.PATHNAME || '';

    resolvedPath = options.full
      ? ('/' + fullPath + resolvedPath).replace(/\/+/g, '/').replace(/\/\*$/, '').replace(/\/$/, '')
      : resolvedPath.replace(/\/+/g, '/');

    if (options.lastSegmentOnly) {
      const segments = resolvedPath.split('/').filter(Boolean);

      resolvedPath = segments.pop() || '';
    }

    return options.isWildcard ? resolvedPath : resolvedPath.replace(/\*$/, '');
  }

  return types[key];
};

export const GENERAL = {
  AUTH: {
    ADMIN: {
      LOGIN: '/admin',
      SIGN_UP: '/admin/signup',
      REDIRECT: `${PAGE.ADMIN_PANEL}/events/*`,
    },
    USER: {
      LOGIN: '/login',
      SIGN_UP: '/signup',
      REDIRECT: PAGE.DASHBOARD,
    },
  },
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n

export const HOME: IRouters = {
  ADMIN_LOGIN: `${GENERAL.AUTH.ADMIN.LOGIN}`,
  ADMIN_SIGN_UP: `${GENERAL.AUTH.ADMIN.SIGN_UP}`,
  USER_LOGIN: `${GENERAL.AUTH.USER.LOGIN}`,
  USER_SIGN_UP: `${GENERAL.AUTH.USER.SIGN_UP}`,
}; //TODO [Deprecated]: Will be remove in version 0.1.7.n
