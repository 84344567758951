import './index.scss';
import classNames from 'classnames';
import React, { FC, ReactElement, ReactEventHandler, ReactNode } from 'react';

interface IProps {
  active?: boolean;
  children?: ReactNode;
  onClick?: any;
  onEvent?(e): void;
  className?: string;
  variant?: string;
  size?: string;
  bold?: boolean;
  auto?: boolean;
  attr?: string;
  innerRef?: any;
  monochrome?: boolean;
  off?: boolean;
  compact?: boolean;
  dataTour?: string;
  width?: string;
  error?: boolean;
  user?: boolean;
  loading?: boolean;
  dataTestid?: string;
}

const Button: FC<IProps> = function ({
  active,
  children,
  onClick,
  onEvent,
  className = '',
  variant = 'primary',
  size = 'lg',
  bold,
  auto,
  attr,
  innerRef,
  monochrome,
  compact,
  off,
  width,
  dataTour,
  error,
  user,
  loading,
  dataTestid,
}): ReactElement {
  const onClickButton: ReactEventHandler = (e): void => {
    onClick && onClick(e);
    onEvent && onEvent(e);
  };

  const attributeView = () => {
    if (!attr) return null;
    const [property, value] = attr.split('=');

    return { [property]: value };
  };

  const renderLoader = () => {
    return <div className="button__loader" />;
  };

  const renderButton: Function = (): ReactNode => {
    const classesButton = classNames('button--eject', className, {
      [`button--${variant}`]: variant,
      'button--active': active,
      'button--compact': compact,
      'button--monochrome': monochrome,
      'button--loading': loading,
      'button--off': off,
      'button--b': bold,
      'button--user': user,
      'button--error': error,
      'button--auto': auto,
      [`button--${size}`]: size !== 'none',
      [`button--w-${width}`]: width,
    });

    const isParams = {
      ...attributeView(),
      ...(innerRef && { ref: innerRef }),
      'data-tut': dataTour,
    };

    return (
      <div data-testid={dataTestid} className={classesButton} onClick={onClickButton} {...isParams}>
        {children}
        {loading && renderLoader()}
      </div>
    );
  };

  return renderButton();
};

export default Button;
